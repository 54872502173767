import React from "react";
import moment from "moment";
import { format } from "date-fns";
import { BiCheck, BiHelpCircle, BiXCircle } from "react-icons/bi";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
import { professionalDefaultImg } from "src/theme/Images";

const BookingDetails = ({ handleAction, bookingDetails, key }: any) => {
    const { t } = useTranslation();
    return (
        <div
            key={key}
            className={` bg-cardBackground rounded-[12px] p-[16px] lg:shadow-[0px_4px_10px_0px_#0000000D] flex flex-wrap items-center justify-between cursor-pointer font-primary transition hover:shadow-[0px_4px_25px_0px_#00000026] shadow-[0px_4px_10px_0px_#0000000D] z-10 bg-[#FEFEFE]`}
            onClick={handleAction("booking", bookingDetails)}
        >
            <div className="flex item-center gap-[16px] w-full">
                <div className="relative">
                    <div className="w-[54px] min-w-[54px] relative">
                        <img
                            src={bookingDetails.staff ? bookingDetails?.staff?.profile_image_url : professionalDefaultImg}
                            alt=""
                            onError={onError("Professional")}
                            className="h-[54px] min-w-[54px] w-[54px] object-cover relative rounded-[8px]"
                        />
                        <div className="flex gap-[10px] flex-wrap items-center bg-white rounded-full w-[19px] h-[19px] border border-white absolute bottom-[-4px] right-[-4px]">
                            {bookingDetails.status === "confirmed" && (
                                <span className="bg-success rounded-full p-[1px] h-[18px] w-[18px] min-w-[18px]">
                                    <BiCheck className="text-white" />
                                </span>
                            )}
                            {bookingDetails.status === "pending" && (
                                <span className="bg-pending rounded-full p-[1px] h-[18px] w-[18px] min-w-[18px]">
                                    <BiHelpCircle className="text-white" />
                                </span>
                            )}
                            {bookingDetails.status === "declined" && (
                                <span className="bg-danger rounded-full p-[1px] h-[18px] w-[18px] min-w-[18px]">
                                    <BiXCircle className="text-white" />
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex flex-col justify-center">
                    <div className="flex flex-row gap-1">
                        <p className="text-textGrey text-[11px] leading-[16.5px] font-normal uppercase">{t("Appointment")}</p>
                        <p className="text-txtAppointmentColor text-[11px] leading-[16.5px] font-normal">#{bookingDetails?.receipt_code}</p>
                    </div>
                    <p className="font-normal text-[14px] text-txtAppointmentColor leading-[16.94px] tracking-[-0.01em]">Appointment with {bookingDetails?.staff?.full_name}</p>
                    <p className="text-textGrey text-[12px] leading-[18px] font-normal">
                        {moment(bookingDetails.booking_date).format("dddd, DD MMM YYYY • ")}
                        {format(new Date(`${bookingDetails.booking_date} ${bookingDetails.booking_start_time}`), "hh:mm-")}
                        {format(new Date(`${bookingDetails.booking_date} ${bookingDetails.booking_end_time}`), "hh:mm a")}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BookingDetails;

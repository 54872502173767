import React, { FC, useEffect, useState } from "react";
import { PlaceholderImgPNG } from "src/theme/Images";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { BiCheck, BiHelpCircle, BiXCircle } from "react-icons/bi";
import BookingView from "src/app/Profile/Booking/BookingView";
import { axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { useAppSelector } from "src/redux/hooks";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import Sidebar from "src/components/Sidebar";
import { IInitAction, initAction } from "./Location.interface";
import { currentShop } from "src/redux/reducers/common/Common.slice";
import { onError } from "src/utils/global-functions";
import BookingDetails from "./BookingDetails";
import SliderCarousel from "src/components/SliderCarousel";

interface IBooking {
    isCarouselView?: boolean;
    setBookingCount?: React.Dispatch<React.SetStateAction<number>>;
}

const Booking: FC<IBooking> = ({ isCarouselView = false, setBookingCount }) => {
    const { t } = useTranslation();
    const shop = useAppSelector(currentShop);
    const [isRefresh, setIsRefresh] = useState(true);
    const uiState = useAppSelector((data) => data.UiStates);
    const [bookings, setBookings] = useState([]);
    const [booking, setBooking] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [action, setAction] = useState<IInitAction>(initAction);

    useEffect(() => {
        if (isRefresh) {
            listBookings();
        }
    }, [isRefresh]);

    const listBookings = () => {
        setIsLoading(true);
        const payload = {
            type: "upcoming",
        };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payload, params)
            .then((response) => {
                if (setBookingCount) {
                    setBookingCount(response.data.data.length);
                }
                setBookings(response.data.data);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsLoading(false);
                setIsRefresh(false);
            });
    };

    const handleAction = (type: keyof IInitAction, data?: any) => () => {
        if (type === "booking") {
            setBooking(data);
        }
        setAction(() => ({ ...initAction, [type]: true }));
    };

    const handleClose = () => {
        setAction(initAction);
    };

    const settings = {
        dots: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        speed: 500,
        centerMode: false,
    };

    return isLoading ? (
        <></>
    ) : (
        <div
            className={` ${
                uiState.isMobile ? "pt-0 mb-5 !mt-2 absolute overflow-y-auto booking-container" : "top-0 bottom-0 left-0 right-0 lg:pt-5 px-5 lg:pb-5 absolute overflow-y-auto booking-container"
            } ${bookings.length === 0 && "!mb-0 !pb-0 !mt-0 !pt-0"} `}
        >
            {bookings.length > 0 && (
                <>
                    <div className={` ${uiState.isMobile ? "flex flex-col w-full rounded-[12px] bg-transparent" : "ml-auto flex flex-col w-full max-w-[370px] gap-4 relative"} z-10 `}>
                        {isCarouselView && bookings.length !== 1 ? (
                            <SliderCarousel settings={settings}>
                                {bookings.map((item: IAppointmentBooking) => (
                                    <BookingDetails bookingDetails={item} handleAction={handleAction} key={item.id} />
                                ))}
                            </SliderCarousel>
                        ) : (
                            bookings.map((item: IAppointmentBooking) => <BookingDetails bookingDetails={item} handleAction={handleAction} key={item.id} />)
                        )}
                    </div>
                    <Sidebar
                        isOpen={action.booking}
                        handleClose={handleClose}
                        size="w-[460px]"
                        position={uiState.isMobile ? "bottom" : "right"}
                        isBottomView={uiState.isMobile}
                        customClass="profile-sidebar !h-full "
                        dismissable
                    >
                        {action.booking && booking && <BookingView booking={booking} handleClose={handleClose} setIsRefresh={setIsRefresh} />}
                    </Sidebar>
                </>
            )}
        </div>
    );
};

export default Booking;

import { useAppSelector } from "src/redux/hooks";
import { getBookingInfo, shopSettings } from "src/app/BookAppointment/Booking.slice";
import { allShopProducts, currentShop, currentUser } from "src/redux/reducers/common/Common.slice";

export const useBookingConditionsStatus = () => {
    const bookingInfo = useAppSelector(getBookingInfo);
    const productList = useAppSelector(allShopProducts);
    const user = useAppSelector(currentUser);
    const shop = useAppSelector(currentShop);
    const bookingShopSettings = useAppSelector(shopSettings);

    const hasSelectedRequiredFields = [
        bookingInfo.selectedSpecialist,
        bookingInfo.selectedBookingDate,
        bookingInfo.selectedBookingTime,
        bookingInfo.selectedServices?.length,
        !(productList.length > 0),
        !bookingShopSettings.payment.enable && !(bookingShopSettings.loyalty.totalAmount >= bookingInfo.bookingTotal),
    ].every(Boolean);

    const validateConditions = (...dynamicConditions: boolean[]): boolean => dynamicConditions.every(Boolean);

    return {
        hasSelectedRequiredFields,
        validateConditions,
    };
};

import React from "react";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import { useSpring, animated } from "@react-spring/web";

type Props = {
    service: any;
    isCollapsed: any;
    setIsCollapsed: any;
    index: number;
};

const Info: React.FC<Props> = ({ service, setIsCollapsed, isCollapsed, index }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const name = getLocalizedSetting(service, "name");
    const description = getLocalizedSetting(service, "description");

    const [styles, api] = useSpring(() => ({
        width: "0%",
        opacity: 0,
    }));

    const onMouseEnter = () => {
        api.start({
            width: "50%",
            opacity: 1,
            config: { tension: 300, friction: 20 },
        });
        api.start({
            width: "100%",
            config: { tension: 200, friction: 30 },
        });
    };

    const onMouseLeave = () => {
        api.start({
            width: "90%",
            opacity: 0,
            config: { tension: 250, friction: 25 },
        });
    };

    return (
        <div className="border-b flex flex-col text-TextColor relative border-b-animation border-borderColorPrimary first:border-t pb-6 pt-6" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="w-full cursor-pointer flex justify-between">
                <div className="flex w-full">
                    <h3 className="text-[16px] leading-[19.36px] font-normal uppercase text-TextColor">{name}</h3>
                </div>
                <div className="flex w-full justify-end">
                    <h3 className="text-[16px] leading-[19.36px] font-normal uppercase text-TextColor">{`$${Number(service.price).toFixed(2)} CAD`}</h3>
                </div>
            </div>
            <div className="flex flex-row pt-4">
                <p className="font-light text-[16px] leading-[22px] text-TextColor">{description}</p>
            </div>
            <animated.div
                style={{
                    height: "2px",
                    background: "black",
                    width: styles.width,
                    opacity: styles.opacity,
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                }}
            />
        </div>
    );
};

export default Info;

import { EStripeCardType } from "../Interface";
import { ALLOW_IMAGE_TYPES, LOCAL_STORAGE_KEYS } from "src/constants/common";
import { persistor } from "../redux/store";
import moment from "moment";

import {
    AmericanPNG,
    CreditCardPNG,
    DinersPNG,
    DiscoverPNG,
    JCBPNG,
    MastercardPNG,
    DefaultImageJPG,
    UnionpayPNG,
    VisaPNG,
    productDefaultImg,
    professionalDefaultImg,
    locationDefaultImg,
} from "src/theme/Images";

import { addDays, getDay, startOfWeek } from "date-fns";
import _ from "lodash";

export const logout = async () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem(LOCAL_STORAGE_KEYS.authToken);
    // await persistor.flush();
    // await persistor.purge();
    // await persistor.purge();
    window.location.href = `${window.location.origin}/`;
};

export const getInstagramUsernames = (url: string) => {
    const regex = /^(?:@|(?:https?:\/\/)?(?:www\.)?instagr(?:\.am|am\.com)\/)?(\w+)\/?$/;

    let resultName = "";

    let match = regex.exec(url);
    if (match) {
        resultName = match[1];
    }

    return resultName;
};

export const convertToHumanReadable = (durationString: string) => {
    // Parse the input duration string
    const [hours, minutes, seconds] = durationString.split(":").map(Number);

    // Create a moment duration
    const duration = moment.duration({ hours, minutes, seconds });

    // Check if there are hours and format accordingly
    let formattedDuration;
    if (duration.hours() > 0) {
        formattedDuration = moment.utc(duration.asMilliseconds()).format("H [hour] m [minute]");
    } else {
        formattedDuration = moment.utc(duration.asMilliseconds()).format("m [minute]");
    }

    // Check if hours and minutes are plural
    if (duration.hours() > 1) {
        formattedDuration = formattedDuration.replace("hour", "hours");
    }
    if (duration.minutes() > 1) {
        formattedDuration = formattedDuration.replace("minute", "minutes");
    }

    return formattedDuration;
};

export const CustomeLog = (logData: any) => {
    // console.log("============LogData===========", logData);
};

export const isOnAnyDay = (date: Date, desiredDays: string[]) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = date.getDay();
    const dayName = daysOfWeek[dayIndex];
    return desiredDays.includes(dayName.toLocaleLowerCase());
};

export const getOrdinalSuffix = (d: string) => {
    const day = Number(d);

    if (day >= 11 && day <= 13) {
        return "th";
    }
    switch (day % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};

export const getCardTypeImage = (cardType: string) => {
    switch (cardType?.toUpperCase()) {
        case EStripeCardType.VISA.toUpperCase():
            return VisaPNG;
        case EStripeCardType.MASTER.toUpperCase():
            return MastercardPNG;
        case EStripeCardType.AMERICAN.toUpperCase():
            return AmericanPNG;
        case EStripeCardType.DINER.toUpperCase():
            return DinersPNG;
        case EStripeCardType.DISCOVER.toUpperCase():
            return DiscoverPNG;
        case EStripeCardType.JCB.toUpperCase():
            return JCBPNG;
        case EStripeCardType.UNION.toUpperCase():
            return UnionpayPNG;
        default:
            return CreditCardPNG;
    }
};

export const updateMetaThemeColor = (color: string) => {
    // Remove the current meta
    document.querySelector('meta[name="theme-color"]')?.remove();

    // Add the new one
    var newMeta = document.createElement("meta");
    newMeta.setAttribute("name", "theme-color");
    newMeta.setAttribute("content", color);
    document.querySelector("head")?.appendChild(newMeta);
};

export const getNextDayOfWeek = (dateString: Date, dayOfWeek: string) => {
    const targetDay = dayOfWeek.toLowerCase();
    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    let daysUntilTarget = (getDay(startOfWeek(dateString)) + 7 - getDay(dateString) + days.indexOf(targetDay)) % 7;
    return addDays(dateString, daysUntilTarget);
};

export const getNextAvailableDay = (selectedDay: string, workingDays: string[]) => {
    // Define an array to map day names to their numerical representation
    const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

    // Convert the specific day 'selectedDay' to its numerical representation
    const xIndex = daysOfWeek.indexOf(selectedDay.toLowerCase());

    if (xIndex === -1) {
        // Return an error if the specific day is not found in the array
        return false;
    }

    // Convert the day names in array 'workingDays' to their numerical representation
    const yIndices = workingDays.map((day) => daysOfWeek.indexOf(day.toLowerCase())).filter((index) => index !== -1);

    // Find the next available day from array 'workingDays' that is greater than or equal to 'selectedDay'
    let nextDayIndex = yIndices.find((index) => index > xIndex);

    if (nextDayIndex === undefined) {
        // If no day is found, return the first day in 'workingDays'
        nextDayIndex = yIndices[0];
    }

    // Convert the numerical representation of the next day back to its name
    return daysOfWeek[nextDayIndex];
};

export const calculateTotalTaxes = (subTotal: string, hst: number, gst: number, pst: number) => {
    const totalTaxPercentage = hst + pst + gst;
    const subTotalNumber = parseFloat(subTotal.replace(/[^0-9.-]+/g, ""));
    const taxDecimal = totalTaxPercentage / 100;
    return (subTotalNumber * taxDecimal).toFixed(2);
};

export const getSelectBoxOptionsIdAsValue = (data: Array<any>, value: string = "id", label: string = "name", id: string = "id") => {
    if (!Array.isArray(data)) {
        data = [data];
    }
    return data.map((res) => ({
        ...res,
        value: res[value],
        label: res[label],
        id: res[id],
    }));
};

export const formatInstagramLink = (instagram_link: string) => {
    if (!instagram_link.startsWith("http://") && !instagram_link.startsWith("https://")) {
        return `https://www.instagram.com/${instagram_link}`; //
    }
    return instagram_link; //
};
export const getSlug = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const url = window.location.hostname;
    const isPreview = Boolean(urlParams.get("preview") === "true" ? true : false);

    const slug = url.split(".");
    return {
        slug: process.env.REACT_APP_ENV === "local" ? process.env.REACT_APP_FLAIR_SHOP_ID : slug[0],
        isPreview: isPreview,
    };

    // return process.env.REACT_APP_ENV === "local" ? process.env.REACT_APP_FLAIR_SHOP_ID : slug[0];
};
export const convertBase64ToFile = (appLogoImage: any) => {
    try {
        const imageExtension = appLogoImage.substring(appLogoImage.indexOf("/") + 1, appLogoImage.indexOf(";base64"));

        const currentTimestamp = Date.now();
        const randomNum = Math.floor(1000000000 + Math.random() * 9000000000);
        const filename = `${currentTimestamp}_${randomNum}.${imageExtension}`;

        const base64Data = appLogoImage.replace(/^data:[^;]+;base64,/, "");

        const uint8Array = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

        const blob = new Blob([uint8Array], {
            type: "application/octet-stream",
        });

        const convertedFile = new File([blob], filename, {
            type: "application/octet-stream",
        });
        return {
            convertedFile: convertedFile,
            filename: filename,
        };
    } catch (error) {
        // console.error('Error converting base64 to file:', error);
    }
};
export const getShortName = (name: string) => {
    const shortName = name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase())
        .join("");
    return shortName;
};

enum ImageType {
    Professional = "Professional",
    General = "General",
    Product = "Product",
    Location = "Location",
}

const getDefaultImage = (type: ImageType = ImageType.General): string => {
    switch (type) {
        case ImageType.Professional:
            return professionalDefaultImg;
        case ImageType.Product:
            return productDefaultImg;
        case ImageType.Location:
            return locationDefaultImg;
        default:
            return DefaultImageJPG; // A general default image
    }
};

export const onError = (type?: keyof typeof ImageType) => (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = getDefaultImage(type ? ImageType[type] : ImageType.General);
};

export const checkFileTypeValidation = (uploadedFile: any, size: any) => {
    const validationResults = _.map(uploadedFile, (file: File) => {
        if (!ALLOW_IMAGE_TYPES.includes(file.type)) {
            return {
                result: false,
                message: "Invalid file format. Please upload a valid image file.",
            };
        } else if (file.size > size * 1024) {
            return {
                result: false,
                message: `Please ensure your image is within the maximum allowed size of ${size}kb`,
            };
        } else {
            return { result: true, message: "" };
        }
    });

    const validationResult = _.every(validationResults, (result: { result: boolean }) => result.result === true);

    return {
        result: validationResult,
        message: validationResult ? "" : validationResults[0].message,
    };
};

export const calculateBookingTotal = (selectedServices: any[], selectedProducts: any[], bookingState: any) => {
    const totalServicePrices = selectedServices.reduce((prevPrice, serviceRecord) => {
        if (serviceRecord.price && serviceRecord.quantity) {
            return prevPrice + Number(serviceRecord.price?.price) * serviceRecord.quantity;
        }
        return prevPrice;
    }, 0);

    const totalProductPrices = selectedProducts.reduce((prevPrice, productRecord) => {
        if (productRecord?.selectedVariant?.inventory.price) {
            return prevPrice + Number(productRecord.selectedVariant.inventory.price);
        }
        return prevPrice;
    }, 0);

    // Calculate tax if applicable
    let serviceTax: any = 0;
    if (totalServicePrices && bookingState) {
        serviceTax = calculateTotalTaxes(totalServicePrices.toFixed(2), Number(bookingState.gst), Number(bookingState.hst), Number(bookingState.pst));
    }

    const finalTotal = totalServicePrices + totalProductPrices + Number(serviceTax);

    return finalTotal.toFixed(2);
};

export const formatAmount = (amount: any) =>
    new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    }).format(amount);

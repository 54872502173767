import React, { useEffect, useState } from "react";
import CustomButton from "src/components/CustomButton";
import { Check } from "@untitled-ui/icons-react/build/cjs";
import PopupModal from "src/components/PopupModal";
import InquireForm from "../../Template2/Course/InquireForm";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
import { globalLanguage } from "src/redux/reducers/common/Common.slice";
import { useAppSelector } from "src/redux/hooks";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

interface Props {
    data: any;
}
const Course: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation();
    const { getLocalizedSetting } = useLocalizedSetting();
    const [action, setAction] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const handleInquire = () => {
        setAction(true);
    };

    const handleClose = () => {
        setAction(false);
    };

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                setIsSuccess(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isSuccess]);

    const title = getLocalizedSetting(data, "title");
    const description = getLocalizedSetting(data, "description");
    const buttonTitle = getLocalizedSetting(data, "button");
    return (
        <section className="text-TextColor pt-9 md:spacing-top flex">
            <div className="flex flex-col mdm:flex-row overflow-hidden gap-[24px] lg:gap-8 xl:gap-[54px] w-full">
                <div className="max-mdm:w-full w-[45%] bg-cover bg-center">
                    <h2 className="custom-heading mb-4 md:mb-[20px] mdm:mb-[24px] xls:mb-[50px] flex mdm:hidden">{t("ACADEMY")}</h2>
                    <img src={data.image_url} onError={onError("Product")} alt="" className="w-full aspect-[2.4/1]  mdm:aspect-[13/10] profile-img object-cover cursor-pointer" />
                </div>
                <div className="w-[55%] max-mdm:w-full flex flex-col h-full justify-start items-center">
                    <div className="flex flex-col w-full">
                        <h2 className="custom-heading mb-4 md:mb-[20px] mdm:mb-[24px] xls:mb-[50px] mdm:flex hidden">{t("ACADEMY")}</h2>
                        <div className="flex flex-col gap-3">
                            {data?.is_title && <p className="text-[24px] leading-[36px] md:text-[28px] md:leading-[36px] lg:text-[32px] lg:leading-[38.4px] font-normal">{title}</p>}
                            <div className="flex flex-col md:flex-row  md:items-center md:gap-4 pb-4 mdm:pb-[20px] xls:pb-[32px]">
                                <p className="text-[24px] leading-[36px] font-medium text-TextColor">${Number(data.price).toFixed(2)}</p>
                                <p className="text-[20px] leading-[30px] font-light tracking-[-0.02em]">{data.date}</p>
                            </div>
                        </div>
                        {data.is_description && <p className="text-[16px] leading-[24px] font-light tracking-[-0.02em] mb-4 text-TextColor">{description}</p>}
                        {data?.is_button &&
                            (isSuccess ? (
                                <span className="flex items-center flex-row">
                                    <Check className="text-primary w-5 h-5 mr-2" />
                                    <span className="text-[16px] leading-[24px] font-medium tracking-[-0.01rem] uppercase text-primary">{t("Request submitted.")}</span>
                                </span>
                            ) : (
                                <CustomButton primary className="!h-[48px] uppercase !px-6 lg:!px-6 !font-medium max-w-max" onClick={handleInquire}>
                                    {buttonTitle}
                                </CustomButton>
                            ))}
                        {/* {data?.is_button && (
                            <CustomButton primary className="!h-[48px] uppercase max-w-max !px-6 lg:!px-6 !font-medium">
                                {data.button}
                            </CustomButton>
                        )} */}
                    </div>
                </div>
                {action && (
                    <PopupModal
                        onClose={handleClose}
                        size={`max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[600px] font-primary outline-custom`}
                        className="!pt-5 !pb-0 "
                        dismissible
                        position="center"
                    >
                        <InquireForm setIsSuccess={setIsSuccess} handleClose={handleClose} title={data?.is_title ? data.title : ""} />
                    </PopupModal>
                )}
            </div>
        </section>
    );
};

export default Course;

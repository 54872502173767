import React from "react";
import { useSprings, animated } from "react-spring";
import { IAnimatedTitle } from "./IAnimatedTitle.interface";

const AnimatedTitle: React.FC<IAnimatedTitle> = ({ title, isActive }) => {
    const subtitleLetters = title.split("");

    const letterAnimations = useSprings(
        subtitleLetters.length,
        subtitleLetters.map((_: any, index: number) => ({
            opacity: isActive ? 1 : 0,
            transform: isActive ? "translateX(0)" : "translateX(-100%)",
            from: { opacity: 0, transform: "translateX(-100%)" },
            delay: index * 50,
            config: { tension: 170, friction: 26 },
        })),
    );

    return (
        <div className="animated-title">
            {letterAnimations.map((style, index) => (
                <animated.span key={index} style={style as any}>
                    {subtitleLetters[index]}
                </animated.span>
            ))}
        </div>
    );
};

export default AnimatedTitle;

import React, { useCallback, useLayoutEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { ChevronLeft, ChevronRight } from "@untitled-ui/icons-react/build/cjs";
import { Swiper as SwiperType } from "swiper/types";
import CustomSwiper from "src/components/CustomSwiper/CustomSwiper";
import { useEnableSliderButtons } from "src/hooks/useEnableSliderButtons";
import { onError } from "src/utils/global-functions";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
interface Props {
    data: any;
}

const init = {
    // When window width is <= 768px
    320: {
        slidesPerView: 1,
        spaceBetween: 14,
    },
    640: {
        slidesPerView: 2,
        spaceBetween: 10,
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 20,
    },
    1024: {
        slidesPerView: 2,
        spaceBetween: 25,
    },
    1280: {
        slidesPerView: 3,
        spaceBetween: 24,
    },
    1440: {
        slidesPerView: 3,
        spaceBetween: 28,
    },
    1700: {
        slidesPerView: 4,
        spaceBetween: 32,
    },
};
const Testimonial: React.FC<Props> = ({ data }) => {
    const { getLocalizedSetting } = useLocalizedSetting();
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [breakPoint, setBreakPoint] = useState(init);
    const [swiperKey, setSwiperKey] = useState(0);
    const [currentBreakpoint, setCurrentBreakpoint] = useState("1280");
    const conditions = [
        { breakpoint: "1024", minLength: 2 },
        { breakpoint: "768", minLength: 2 },
        { breakpoint: "1280", minLength: 3 },
        { breakpoint: "1440", minLength: 3 },
        { breakpoint: "1700", minLength: 4 },
    ];
    const isEnableSliderButtons = useEnableSliderButtons(currentBreakpoint, data?.testimonials.length, conditions, true);

    // useLayoutEffect(() => {
    //     if (data?.testimonials.length === 2 || data?.testimonials.length === 3 || data?.testimonials.length === 1) {
    //         setBreakPoint((prev: any) => ({
    //             ...prev,
    //             1024: {
    //                 slidesPerView: data?.testimonials.length,
    //                 spaceBetween: 20,
    //             },
    //             1280: {
    //                 slidesPerView: data?.testimonials.length,
    //                 spaceBetween: 20,
    //             },
    //         }));
    //     } else {
    //         setBreakPoint(init);
    //     }
    //     setSwiperKey((prevKey) => prevKey + 1);
    // }, [data?.testimonials]);

    const handleSlideChange = (event: any) => {
        setCurrentBreakpoint(event.currentBreakpoint);
    };
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const title = getLocalizedSetting(data, "title");
    return (
        <div className="text-TextColor pt-9 md:spacing-top flex flex-col flex-1">
            <div className="flex  flex-col md:flex-row md:justify-between items-center mb-6 md:mb-[54px]">
                {data?.is_title && (
                    <div className="flex w-full">
                        <h2 className="custom-heading w-full uppercase truncate overflow-hidden whitespace-nowrap min-w-0">{title}</h2>
                    </div>
                )}
                <div className="md:flex relative hidden ">
                    <button
                        disabled={!isEnableSliderButtons}
                        onClick={handlePrevious}
                        className={`rounded-full flex justify-center items-center border border-TextColor h-[52px] min-w-[52px] w-[52px] mr-2.5 bg-templateBg  ${
                            !isEnableSliderButtons ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                        }`}
                    >
                        <ChevronLeft className="w-[24px] h-[24px] text-TextColor" />
                    </button>
                    <button
                        disabled={!isEnableSliderButtons}
                        onClick={handleNext}
                        className={`rounded-full flex justify-center items-center border border-TextColor bg-templateBg h-[52px] min-w-[52px] w-[52px] ${
                            !isEnableSliderButtons ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
                        }`}
                    >
                        <ChevronRight className="w-[24px] h-[24px] text-TextColor" />
                    </button>
                </div>
            </div>
            <CustomSwiper
                className="mySwiper w-full testimonialSlider flex flex-col flex-1"
                spaceBetween={32}
                key={swiperKey}
                loop={isEnableSliderButtons}
                setSwiperRef={setSwiperRef}
                onInit={handleSlideChange}
                breakpoints={breakPoint}
            >
                {data?.testimonials?.map((testimonial: any, index: React.Key | null | undefined) => (
                    <>
                        <div className="w-full mx-auto">
                            <SwiperSlide key={index}>
                                <div className="cursor-pointer border border-TextColor py-4 md:py-[18px] xl:py-[20px] 2xl:py-[24px] h-full min-h-auto max-h-auto md:min-h-[280px]  flex flex-1 flex-col justify-between">
                                    <div className="mb-6 px-4 md:px-[18px] xl:px-[20px] 2xl:px-[24px] max-h-[265px] overflow-x-auto">
                                        {testimonial.is_description && (
                                            <p className="text-[18px]  leading-[27px] text-TextColor font-light tracking-[-0.02em]">{getLocalizedSetting(testimonial, "description")}</p>
                                        )}
                                    </div>
                                    <div className="flex items-center gap-4 px-4 md:px-[18px] xl:px-[20px] 2xl:px-[24px]">
                                        <img src={testimonial.image_url} onError={onError("Professional")} className="w-[42px] h-[42px]" alt="" />
                                        <p className="text-[16px] leading-[19.36px] font-light text-TextColor tracking-[-0.02em]">{testimonial.name}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </div>
                    </>
                ))}
            </CustomSwiper>
            {data?.testimonials.length > 1 && (
                <div className="flex justify-center w-full relative mt-4 md:hidden">
                    <button
                        className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] mr-3.5 bg-transparent"
                        onClick={handlePrevious}
                    >
                        <ChevronLeft className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                    <button
                        className="rounded-full flex flex-col justify-center items-center border border-TextColor cursor-pointer h-[24px] w-[24px] min-w-[24px] smd:w-[40px] smd:h-[40px] smd:min-w-[40px] bg-transparent"
                        onClick={handleNext}
                    >
                        <ChevronRight className="text-TextColor w-4 h-4 smd:w-[20px] smd:h-[20px]" />
                    </button>
                </div>
            )}
        </div>
    );
};

export default Testimonial;

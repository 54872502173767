import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ErrorType, IInitialState } from "./Common.interface";
import { axiosGet } from "../../../utils/requestClient";
import { API } from "../../../constants/api";
import { errorCode, LANGUAGE, LOCAL_STORAGE_KEYS } from "../../../constants/common";
import { getSlug } from "../../../utils/global-functions";
import { RootState } from "src/redux/store";
import { bookingGetAllShopSettings, setSelectedShopLocation } from "src/app/BookAppointment/Booking.slice";

const initialState: IInitialState = {
    settings: [],
    products: [],
    locations: [],
    shop: null,
    user: null,
    loading: false,
    error: null,
    template: null,
    globalLanguage: LANGUAGE,
};

export const me = createAsyncThunk("user/me", async (payload, { dispatch, getState, rejectWithValue }) => {
    try {
        const { Common }: any = getState();
        const response = await axiosGet(API.USER.ME, {}, { shop_id: Common.shop.id });
        if (response.data.status === errorCode.success) {
            dispatch(bookingGetAllShopSettings({ shop_id: Common.shop.id }));
            return response.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopSettings = createAsyncThunk("setting/all", async (payload: any, { rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.SETTING.GET, {}, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopLocations = createAsyncThunk("location/all", async (payload: any, { dispatch, rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.LOCATION.LIST, {}, payload);
        if (response.data.status === errorCode.success) {
            if (response.data.data.length === 1) {
                dispatch(setSelectedShopLocation(response.data.data[0]));
            }
            return response.data.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getShop = createAsyncThunk("user/shop/get", async (payload, { dispatch, rejectWithValue }) => {
    try {
        const response = await axiosGet(API.USER_SHOP.GET, {}, { slug: getSlug().slug });
        if (response.data.status === errorCode.success) {
            await dispatch(getAllShopSettings({ shop_id: response.data.data.id }));
            await dispatch(getAllShopLocations({ shop_id: response.data.data.id }));
            return response.data.data;
        }

        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const getAllShopProducts = createAsyncThunk("product/all", async (payload: any, { rejectWithValue }) => {
    try {
        const response = await axiosGet(API.PRODUCT.LIST, payload.data, payload.params);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setGlobalLanguage: (state, { payload }) => {
            state.globalLanguage = payload;
            localStorage.setItem(LOCAL_STORAGE_KEYS.currentLanguage, payload);
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(me.pending, (state) => {
                state.loading = true;
            })
            .addCase(me.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.user = payload.data;
            })
            .addCase(me.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopSettings.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopSettings.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                const isPreview = getSlug().isPreview;
                // console.log("isPreview", isPreview);
                state.settings = payload.data;
                const settings = payload.data;

                // console.log("settings", settings);
                if (settings && settings.length > 0) {
                    const templateSetting = settings.find((setting: { type: string }) => setting.type === (isPreview ? "template_preview" : "template"));
                    // console.log("templateSetting", templateSetting);
                    if (templateSetting) {
                        const template = templateSetting.value.template;
                        state.template = template;
                    }
                    const appearance = settings.find((setting: { type: string }) => setting.type === "appearance");
                    if (appearance) {
                        const {
                            base_theme: baseTheme,
                            brand_color: brandColor,
                            brand_text_color: brandTextColor,
                            font_family: fontFamily,
                            logo_font_family: logoFontFamily,
                            font_weight: fontWeight,
                            element_style: elementStyle,
                            brand_navigation_color: brandNavigationColor,
                        } = appearance.value;
                        const contentBackgroundColor = baseTheme === "dark" ? "#0C111D" : "#FFFFFF";
                        const cardBackgroundColor = baseTheme === "dark" ? "#161B26" : "#FFFFFF";
                        const mainTextColor = baseTheme === "dark" ? "#FAF8F7" : "#0C111D";
                        const borderColorPrimary = baseTheme === "dark" ? "#2F3746" : "#E3E7EF";
                        const borderColorSecondary = baseTheme === "dark" ? "#2F3746" : "#CACFDC";
                        const borderCounter = baseTheme === "dark" ? "#2F3746" : "#D0D5DD";
                        const textCounterColor = baseTheme === "dark" ? "#FAF8F7" : "#344054";
                        const dropdownBackgroundColor = baseTheme === "dark" ? "#303030" : "#FFFFFF";
                        const productBackgroundColor = baseTheme === "dark" ? "#141414" : "#FFFFFF";
                        const bgContent = baseTheme === "dark" ? "#1E1E1E" : "#F7F7F8";
                        const tooltipBg = baseTheme === "dark" ? "#ffffff" : "#000000";
                        const tooltipTextColor = baseTheme === "dark" ? "#000000" : "#ffffff";
                        const mainBackgroundColor = baseTheme === "dark" ? "#1A2030" : "#F8F8F8";
                        const containerBgColor = baseTheme === "dark" ? "#0C111D" : "#FFFFFF";
                        const sidebarBgColor = baseTheme === "dark" ? "#0C111D" : "#FEFEFE";
                        const navbarBgColor = baseTheme === "dark" ? "#0C111D" : "#FEFEFE";
                        const appointmentBgColor = baseTheme === "dark" ? "#2A2F3C" : "#F6F8FE";
                        const mapPinBgColor = baseTheme === "dark" ? "#000000" : "#BDBDBD";
                        const mapPinBorderColor = baseTheme === "dark" ? "#FFFFFF" : "#D1D2D4";
                        const steperBackgroundColor = baseTheme === "dark" ? "#2A2F3C" : "#F7F7F8";
                        const stepcontentBackgroundColor = baseTheme === "dark" ? "#2F3746" : "#F7F7F8";
                        const footerPolicyBackgroundColor = baseTheme === "dark" ? "#161B26" : "#F3F4F6";
                        const successMessagesBorder = baseTheme === "dark" ? "#364F44" : "#079455";
                        const successMessagesBg = baseTheme === "dark" ? "#30493E" : "#EEFBF5";
                        const boxShadowColor = baseTheme === "dark" ? "#30493E" : "#e0eaff";
                        const uploadProfileBg = baseTheme === "dark" ? "#2A2F3C" : "#F2F4F7";
                        const uploadProfileBorder = baseTheme === "dark" ? "#2A2F3C" : "#EAECF0";
                        const uploadInputBg = baseTheme === "dark" ? "#2A2F3C" : "#FFFFFF";

                        const templateBg = baseTheme === "dark" ? "#161B26" : "#faf8f7";
                        const templateTextColor = baseTheme === "dark" ? "#FFFFFF" : "#060606";
                        const templateCardBg = baseTheme === "dark" ? "#000000" : "#ffffff";
                        const templateCardBorderColor = baseTheme === "dark" ? "#2F3746" : "#E0E0E0";
                        const ProductBorderColor = baseTheme === "dark" ? "#D6D7E2" : "#31376C";
                        const templateReview = baseTheme === "dark" ? "#3f4759" : "#CBD3E3";

                        let brandBorderRadius = "8px";
                        let brandBorderRadiusSecoundary = "18px";
                        switch (elementStyle) {
                            case "minimal":
                                brandBorderRadius = "8px";
                                brandBorderRadiusSecoundary = "8px";
                                break;
                            case "sharp":
                                brandBorderRadius = "0px";
                                brandBorderRadiusSecoundary = "0px";
                                break;
                            case "curved":
                                brandBorderRadius = "100%";
                                brandBorderRadiusSecoundary = "18px";
                                break;
                            default:
                                break;
                        }
                        const r = parseInt(brandColor.slice(1, 3), 16);
                        const g = parseInt(brandColor.slice(3, 5), 16);
                        const b = parseInt(brandColor.slice(5, 7), 16);

                        const darkFactor = 0.8; // Set a smaller value for a much darker color (e.g., 0.4 makes it 60% darker)

                        const darkerR = Math.floor(r * darkFactor);
                        const darkerG = Math.floor(g * darkFactor);
                        const darkerB = Math.floor(b * darkFactor);

                        const red = parseInt(brandColor.slice(1, 3), 16);
                        const green = parseInt(brandColor.slice(3, 5), 16);
                        const blue = parseInt(brandColor.slice(5, 7), 16);

                        // Ensure RGB values remain in valid range and create the RGBA string
                        const brandColorHoverDark = `rgba(${Math.max(0, darkerR)}, ${Math.max(0, darkerG)}, ${Math.max(0, darkerB)}, 1)`;

                        // Function to calculate luminance
                        const getLuminance = function (r1: number, g1: number, b1: number): number {
                            const a = [r1, g1, b1].map(function (v) {
                                v /= 255;
                                return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
                            });
                            return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
                        };

                        const luminance = getLuminance(red, green, blue);
                        // Determine if the color is dark or light based on the luminance
                        const isDark = luminance < 0.5;

                        const brandColorLight = `rgba(${r}, ${g}, ${b}, 0.5)`;
                        const brandColorDark = `rgba(${Math.max(0, darkerR)}, ${Math.max(0, darkerG)}, ${Math.max(0, darkerB)}, 1)`;

                        const brandColorLightDark = isDark ? brandColorLight : brandColorDark;

                        document.documentElement.style.setProperty("--brand-color-light", brandColorLight);
                        document.documentElement.style.setProperty("--brand-color-dark", brandColorDark);
                        document.documentElement.style.setProperty("--brand-color-LightDark", brandColorLightDark);

                        const brandColorHover = `rgba(${r}, ${g}, ${b}, 0.1)`;
                        const borderColorHover = `rgba(${r}, ${g}, ${b}, 0.2)`;
                        document.documentElement.style.setProperty("--brand-color-button", brandColorHoverDark);
                        document.documentElement.style.setProperty("--brand-color", brandColor);
                        document.documentElement.style.setProperty("--brand-color-hover", brandColorHover);
                        document.documentElement.style.setProperty("--border-color-hover", borderColorHover);
                        document.documentElement.style.setProperty("--brand-text-color", brandTextColor);
                        document.documentElement.style.setProperty("--brand-navigation-color", brandNavigationColor);
                        document.documentElement.style.setProperty("--content-background-color", contentBackgroundColor);
                        document.documentElement.style.setProperty("--card-background-color", cardBackgroundColor);
                        document.documentElement.style.setProperty("--main-text-color", mainTextColor);
                        document.documentElement.style.setProperty("--brand-font-family", fontFamily);
                        document.documentElement.style.setProperty("--logo-font-family", logoFontFamily);
                        document.documentElement.style.setProperty("--logo-font-weight", fontWeight);
                        document.documentElement.style.setProperty("--brand-border-radius", brandBorderRadius);
                        document.documentElement.style.setProperty("--brand-border-radius-secoundary", brandBorderRadiusSecoundary);
                        document.documentElement.style.setProperty("--border-color-primary", borderColorPrimary);
                        document.documentElement.style.setProperty("--border-color-secondary", borderColorSecondary);
                        document.documentElement.style.setProperty("--dropdown-background-color", dropdownBackgroundColor);
                        document.documentElement.style.setProperty("--product-background-color", productBackgroundColor);
                        document.documentElement.style.setProperty("--bg-content", bgContent);
                        document.documentElement.style.setProperty("--tooltip-bg", tooltipBg);
                        document.documentElement.style.setProperty("--tooltip-text-color", tooltipTextColor);
                        document.documentElement.style.setProperty("--main-background-color", mainBackgroundColor);
                        document.documentElement.style.setProperty("--container-background-color", containerBgColor);
                        document.documentElement.style.setProperty("--navbar-background-color", navbarBgColor);
                        document.documentElement.style.setProperty("--appointment-background-color", appointmentBgColor);
                        document.documentElement.style.setProperty("--border-counter", borderCounter);
                        document.documentElement.style.setProperty("--sidebar-background-color", sidebarBgColor);
                        document.documentElement.style.setProperty("--text-counter-color", textCounterColor);
                        document.documentElement.style.setProperty("--map-pin-background-color", mapPinBgColor);
                        document.documentElement.style.setProperty("--map-pin-border-color", mapPinBorderColor);
                        document.documentElement.style.setProperty("--steper-background-color", steperBackgroundColor);
                        document.documentElement.style.setProperty("--step-content-background-color", stepcontentBackgroundColor);
                        document.documentElement.style.setProperty("--foter-policy-background-color", footerPolicyBackgroundColor);
                        document.documentElement.style.setProperty("--success-messages-border-color", successMessagesBorder);
                        document.documentElement.style.setProperty("--success-messages-background-color", successMessagesBg);
                        document.documentElement.style.setProperty("--box-shadow-color", boxShadowColor);
                        document.documentElement.style.setProperty("--upload-profile-border-color", uploadProfileBorder);
                        document.documentElement.style.setProperty("--upload-profile-background-color", uploadProfileBg);
                        document.documentElement.style.setProperty("--upload-input-background-color", uploadInputBg);

                        document.documentElement.style.setProperty("--template-background-color", templateBg);
                        document.documentElement.style.setProperty("--template-text-color", templateTextColor);
                        document.documentElement.style.setProperty("--template-card-background-color", templateCardBg);
                        document.documentElement.style.setProperty("--template-card-border-color", templateCardBorderColor);
                        document.documentElement.style.setProperty("--product-border-color", ProductBorderColor);
                        document.documentElement.style.setProperty("--template-review-color", templateReview);
                    }
                }
            })
            .addCase(getAllShopSettings.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getShop.pending, (state) => {
                state.loading = true;
            })
            .addCase(getShop.fulfilled, (state, { payload }: any) => {
                state.shop = payload;
                state.loading = false;
                if (payload && payload.admin_logo_image_url) {
                    const favicon: any = document.getElementById("favicon");
                    if (favicon) {
                        favicon.href = payload.admin_logo_image_url;
                    }
                }
            })
            .addCase(getShop.rejected, (state, action: any) => {
                state.loading = false;
            })
            .addCase(getAllShopLocations.pending, (state, { payload }: any) => {
                state.loading = true;
            })
            .addCase(getAllShopLocations.fulfilled, (state, { payload }: any) => {
                state.locations = payload;
                state.loading = false;
            })
            .addCase(getAllShopLocations.rejected, (state, { payload }: any) => {
                state.loading = false;
            })
            .addCase(getAllShopProducts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllShopProducts.fulfilled, (state, { payload }: any) => {
                state.loading = false;
                state.products = payload.data;
            })
            .addCase(getAllShopProducts.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export default commonSlice.reducer;

export const { setGlobalLanguage } = commonSlice.actions;

export const currentUser = (state: RootState) => state.Common.user || null;
export const currentShop = (state: RootState) => state.Common.shop || null;
export const currentSettings = (state: RootState) => state.Common.settings || [];
export const allShopProducts = (state: RootState) => state.Common.products || [];
export const allShopLocations = (state: RootState) => state.Common.locations || [];
export const allLanguages = (state: RootState) => {
    let languages = ["en"];
    if (state.Common.settings) {
        const appearance = state.Common.settings.find((setting: any) => setting.type === "appearance");
        if (appearance) {
            languages = appearance.value.languages;
        }
    }
    return languages;
};
export const globalLanguage = (state: RootState) => state.Common.globalLanguage || LANGUAGE;

import { useEffect, useState } from "react";
import { ROUTES } from "src/constants/routes";
import i18n from "src/i18n";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { allLanguages, globalLanguage, setGlobalLanguage } from "src/redux/reducers/common/Common.slice";

export const useLanguage = () => {
    const dispatch = useAppDispatch();
    const currentLanguage = useAppSelector(globalLanguage);
    const languages = useAppSelector(allLanguages);
    const [languageOptions, setLanguageOptions] = useState([]);

    useEffect(() => {
        const originalLanguage = i18n.language;
        i18n.changeLanguage(currentLanguage);
        return () => {
            i18n.changeLanguage(originalLanguage);
        };
    }, [i18n, currentLanguage]);

    useEffect(() => {
        setLanguageOptions(getLanguageOptions());
    }, [languages]);

    const getLanguageOptions = () => {
        let options: any = [];
        languages.forEach((lang: any) => {
            options.push({
                value: lang,
                label: lang.toUpperCase(),
                id: lang,
            });
        });
        return options;
    };

    const handleLanguage = (selectedOption: { value: string; label: string; id: string }) => {
        const value = selectedOption.value;
        dispatch(setGlobalLanguage(value));
        window.location.href = ROUTES.HOME;
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(setGlobalLanguage(value));
    };

    return { languageOptions, languages, handleLanguage, handleRadioChange };
};

import React from "react";
import MDProfessionalSwiper from "./MDProfessionalSwiper";

interface Props {
    data: any;
    locationSetting: any;
}

const Professional: React.FC<Props> = ({ data, locationSetting }) =>
    data?.staff.length > 0 ? (
        <div className="w-full pt-9 md:spacing-top md:text-txtcolor">
            <div className="flex flex-col">
                <div className="flex flex-wrap justify-between gap-1 ">
                    <MDProfessionalSwiper data={data} />
                </div>
            </div>
        </div>
    ) : null;

export default Professional;

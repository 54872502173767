import React, { FC, useCallback, useEffect, useState } from "react";
import { Swiper as SwiperType } from "swiper/types";
import { IProduct, IproductData, IProductInfo, IVariant } from "./Product.interface";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { allShopLocations, currentShop, globalLanguage } from "src/redux/reducers/common/Common.slice";
import SelectBox from "src/components/SelectBox";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import WProduct from "./WProduct";
import MProduct from "./MProduct";
import { addProduct, getBookingInfo, setBookingInitialState, setSelectedShopLocation } from "src/app/BookAppointment/Booking.slice";
import { PATH } from "src/constants/path";
import { useNavigate } from "react-router-dom";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import AnimatedTitle from "src/components/AnimatedTitle/AnimatedTitle";

const Product: FC<IProduct> = ({ data, sectionIndex, hasProductAnimated }) => {
    const shopLocations: any[] = useAppSelector(allShopLocations);
    const { getLocalizedSetting } = useLocalizedSetting();
    const currentLanguage = useAppSelector(globalLanguage);
    const bookingState = useAppSelector(getBookingInfo);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((uiStateData) => uiStateData.UiStates);
    const [swiperRef, setSwiperRef] = useState<SwiperType>();
    const [productList, setProductList] = useState([]);
    const [productData, setProductData] = useState<IproductData[][]>([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const getProducts = () => {
        setProductList([]);
        setIsLoading(true);
        const payload = {
            params: {
                shop_id: shop.id,
                location_id: selectedLocation,
            },
            data: {
                booking_id: null,
            },
        };
        axiosGet(API.PRODUCT.LIST, payload.data, payload.params)
            .then((response) => {
                const responseData = response.data.data;
                setProductList(responseData);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        const locationOptionsData: any = shopLocations.map((location: any) => ({
            value: location.id,
            label: location.name,
            id: location.id,
        }));

        setLocationOptions(locationOptionsData);
        if (!selectedLocation) {
            setSelectedLocation(locationOptionsData[0].id);
        }
    }, [shopLocations]);

    useEffect(() => {
        if (selectedLocation) {
            getProducts();
        }
    }, [selectedLocation, currentLanguage]);

    useEffect(() => {
        const mainData = productInPairs(productList);
        setProductData(mainData || []);
    }, [productList]);

    const productInPairs = (array: IproductData[]) => {
        const result: IproductData[][] = [];
        if (array && array.length) {
            for (let i = 0; i < array.length; i += 4) {
                result.push(array.slice(i, i + 4));
            }
        }
        return result;
    };
    const handlePrevious = useCallback(() => {
        swiperRef?.slidePrev();
    }, [swiperRef]);
    const handleNext = useCallback(() => {
        swiperRef?.slideNext();
    }, [swiperRef]);

    const handleLocation = (e: any) => {
        setSelectedLocation(Number(e.value));
    };

    const handleProduct = (selectedProductData: IProductInfo, variant: IVariant) => () => {
        if (selectedLocation !== bookingState.selectedShopLocation?.id || bookingState.isModifyingAppointment) {
            dispatch(setBookingInitialState());
        }
        dispatch(addProduct({ ...selectedProductData, selectedVariant: variant }));
        if (selectedLocation) {
            dispatch(setSelectedShopLocation(shopLocations.find((locationData: any) => locationData.id === selectedLocation)));
            navigate(PATH.APPOINTEMENT);
        }
    };

    const title = getLocalizedSetting(data, "title");
    return (
        <>
            <div className="mb-5">
                <div className="flex lg:items-center gap-3 flex-col lg:flex-row lg:justify-between mb-[18px]">
                    <div className="slice-label">
                        <span className="num">{sectionIndex}</span>
                        {data?.is_title && (
                            <span className="slice-text">
                                <AnimatedTitle title={title} isActive={hasProductAnimated} />
                            </span>
                        )}
                    </div>
                    <SelectBox
                        placeholder={"All Locations"}
                        name="location"
                        allowClear={false}
                        options={locationOptions}
                        inputClass="w-full lg:w-[190px]"
                        searchable={true}
                        onChange={handleLocation}
                        value={locationOptions.filter((item: any) => item.value === selectedLocation)}
                        disabled={false}
                    />
                </div>
                {uiState.isTablet ? (
                    <WProduct isLoading={isLoading} productData={productData} handlePrevious={handlePrevious} handleNext={handleNext} setSwiperRef={setSwiperRef} handleProduct={handleProduct} />
                ) : (
                    <MProduct isLoading={isLoading} productList={productList} handlePrevious={handlePrevious} handleNext={handleNext} setSwiperRef={setSwiperRef} handleProduct={handleProduct} />
                )}
            </div>
        </>
    );
};

export default Product;

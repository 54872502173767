import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IShopLocation, ISpecialist, ISpecialistService } from "../../Interface";
import { EBookingTabState } from "src/app/BookAppointment/bookAppointment.interface";
import { RootState } from "src/redux/store";
import { axiosGet } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { errorCode } from "src/constants/common";

const initialState = {
    modifyingAppointmentId: undefined,
    bookingPreview: [],
    isModifyingAppointment: false,
    isShowFinalizeBooking: false,
    selectedShopLocation: undefined,
    selectedSpecialist: undefined,
    selectedBookingDate: undefined,
    selectedBookingTime: undefined,
    selectedServices: [],
    selectedProducts: [],
    paymentOption: null,
    oldPaymentOption: null,
    paymentType: null,
    isValidPaymentType: false,
    step: EBookingTabState.TAB_MENU,
    isShowNoShowPolicy: false,
    isShowOrderSummary: false,
    isNoShowPolicyAccepted: false,
    selectedPromocode: undefined,
    tempStore: {
        selectedSpecialist: undefined,
        selectedBookingDate: undefined,
        selectedBookingTime: undefined,
        selectedServices: [],
        selectedProducts: [],
        selectedBookingDateTime: undefined,
        SelectionCount: 0,
    },
    productCategoryFilterValue: null,
    isCardAdd: false,
    submitTriggered: false,
    isCardLoading: false,
    bookingTotal: 0,
    isNoShowPolicyVisible: false,
    settings: {
        cancellation: { status: false, cancellation_charge: 0, cancellation_type: "amount", cancellation_window: 0 },
        noShow: { status: false, no_show_charge: 0, no_show_type: "amount" },
        loyalty: { status: false, receive: { point: 0, amount: 0 }, spend: { point: 0, amount: 0 }, totalAmount: 0 },
        payment: { enable: false },
        refer: {
            status: false,
            referee: 0,
            refferer: 0,
            referee_type: "value",
            refferer_type: "value",
        },
    },
    isCompleted: false,
};

export interface BookingPreviewItem {
    key: "selectedSpecialist" | "selectedServices" | "selectedBookingDateTime";
    value: any;
}

export interface ErrorType {
    message: string;
    status?: string;
    data?: any;
}

interface Settings {
    cancellation: {
        status: boolean;
        cancellation_charge: number;
        cancellation_type: "amount" | "percentage";
        cancellation_window: number;
    };
    noShow: {
        status: boolean;
        no_show_charge: number;
        no_show_type: "amount" | "percentage";
    };
    loyalty: {
        status: boolean;
        receive: { point: number; amount: number };
        spend: { point: number; amount: number };
        totalAmount: number;
    };
    payment: { enable: boolean };
    refer: {
        referee: number;
        refferer: number;
        referee_type: string;
        refferer_type: string;
        status: boolean;
    };
}

export interface IStateType {
    modifyingAppointmentId: number | undefined;
    bookingPreview: BookingPreviewItem[];
    isModifyingAppointment: boolean;
    isShowFinalizeBooking: boolean;
    selectedShopLocation: IShopLocation | undefined;
    selectedSpecialist: ISpecialist | undefined;
    selectedBookingDate: string | undefined;
    selectedBookingTime: string | undefined;
    selectedServices: ISpecialistService[];
    selectedProducts: any[];
    oldPaymentOption: "pay_now" | "pay_later" | null;
    paymentOption: "pay_now" | "pay_later" | null;
    paymentType: "cash" | "stripe" | "pos" | "loyalty" | "other" | null;
    isValidPaymentType: boolean;
    step: EBookingTabState;
    isShowNoShowPolicy: boolean;
    isShowOrderSummary: boolean;
    isNoShowPolicyAccepted: boolean;
    selectedPromocode: any | undefined;
    tempStore: {
        selectedSpecialist: ISpecialist | undefined;
        selectedBookingDate: string | undefined;
        selectedBookingTime: string | undefined;
        selectedBookingDateTime: string | undefined;
        selectedServices: ISpecialistService[];
        selectedProducts: any[];
        SelectionCount: number;
    };
    productCategoryFilterValue: null | any;
    isCardAdd: boolean;
    submitTriggered: boolean;
    isCardLoading: boolean;
    bookingTotal: number;
    isNoShowPolicyVisible: boolean;
    settings: Settings;
    isCompleted: boolean;
}

export const bookingGetAllShopSettings = createAsyncThunk("setting/all", async (payload: any, { rejectWithValue, getState }) => {
    try {
        const response = await axiosGet(API.SETTING.GET, {}, payload);
        if (response.data.status === errorCode.success) {
            return response.data;
        }
        return rejectWithValue(response as ErrorType);
    } catch (err: any) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data as ErrorType);
    }
});

export const bookingSlice = createSlice({
    name: "booking",
    initialState: initialState as unknown as IStateType,
    reducers: {
        setBookingInitialState: (state) => {
            Object.assign(state, initialState);
        },
        setModifyingAppointmentId: (state, { payload }) => {
            state.modifyingAppointmentId = payload;
        },
        setIsModifyingAppointment: (state, { payload }) => {
            state.isModifyingAppointment = payload;
        },
        setIsShowFinalizeBooking: (state, { payload }) => {
            state.isShowFinalizeBooking = payload;
        },
        setSelectedShopLocation: (state, { payload }) => {
            state.selectedShopLocation = payload;
        },
        setSelectedSpecialist: (state, { payload }) => {
            state.selectedSpecialist = payload;
        },
        setSelectedBookingDate: (state, { payload }) => {
            state.selectedBookingDate = payload;
        },
        setSelectedBookingTime: (state, { payload }) => {
            state.selectedBookingTime = payload;
        },
        setSelectedServices: (state, { payload }) => {
            state.selectedServices = payload;
        },
        setSelectedProducts: (state, { payload }) => {
            state.selectedProducts = payload;
        },
        setOldPaymentOption: (state, { payload }) => {
            state.oldPaymentOption = payload;
        },
        addProduct: (state, { payload }) => {
            state.selectedProducts = [...state.selectedProducts, payload];
        },
        removeProduct: (state, { payload }) => {
            state.selectedProducts = state.selectedProducts.filter((_, idx) => idx !== payload);
        },
        setStep: (state, { payload }) => {
            state.step = payload;
        },
        setTempStore: (state, { payload }) => {
            state.tempStore = { ...state.tempStore, ...payload };
        },
        setPaymentType: (state, { payload }) => {
            state.paymentType = payload;
        },
        setPaymentOption: (state, { payload }) => {
            state.paymentOption = payload;
        },
        setIsValidPaymentType: (state, { payload }) => {
            state.isValidPaymentType = payload;
        },
        setIsShowNoShowPolicy: (state, { payload }) => {
            state.isShowNoShowPolicy = payload;
        },
        setIsShowOrderSummary: (state, { payload }) => {
            state.isShowOrderSummary = payload;
        },
        setIsNoShowPolicyAccepted: (state, { payload }) => {
            state.isNoShowPolicyAccepted = payload;
        },
        setSelectedPromocode: (state, { payload }) => {
            state.selectedPromocode = payload;
        },
        setProductCategory: (state, { payload }) => {
            state.productCategoryFilterValue = payload;
        },
        setIsCardAdded: (state, { payload }) => {
            state.isCardAdd = payload;
        },
        setSubmitTriggered: (state, { payload }) => {
            state.submitTriggered = payload;
        },
        setIsCardLoading: (state, { payload }) => {
            state.isCardLoading = payload;
        },
        setPreview: (state, { payload }) => {
            const { key, value } = payload;
            const existingItemIndex = state.bookingPreview.findIndex((item) => item.key === key);

            if (value === undefined || value === null || (Array.isArray(value) && value.length === 0)) {
                if (existingItemIndex !== -1) {
                    state.bookingPreview.splice(existingItemIndex, 1);
                }
            } else {
                if (existingItemIndex !== -1) {
                    state.bookingPreview[existingItemIndex].value = value;
                } else {
                    state.bookingPreview.push({ key, value });
                }
            }
        },
        setBookingTotal: (state, { payload }) => {
            state.bookingTotal = payload;
        },
        setNoShowPolicyVisible: (state, { payload }) => {
            state.isNoShowPolicyVisible = payload;
        },
        setBookingComplete: (state, { payload }) => {
            state.isCompleted = payload;
        },
        setSettings: (state, { payload }) => {
            if (payload.cancellation) {
                state.settings.cancellation = { ...state.settings.cancellation, ...payload.cancellation };
            }
            if (payload.noShow) {
                state.settings.noShow = { ...state.settings.noShow, ...payload.noShow };
            }
            if (payload.loyalty) {
                state.settings.loyalty = { ...state.settings.loyalty, ...payload.loyalty };
            }
            if (payload.payment) {
                state.settings.payment = payload.payment;
            }
            if (payload.refer) {
                state.settings.refer = payload.refer;
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(bookingGetAllShopSettings.fulfilled, (state, { payload }: any) => {
            const settings = payload.data;

            if (settings && settings.length > 0) {
                const cancellationSettings = settings?.find((setting: any) => setting.type === "cancellation");
                const noShowcurrentSettings = settings?.find((setting: any) => setting.type === "no_show");
                const loyaltySettings = settings?.find((setting: any) => setting.type === "loyalty");
                const referSettings = settings?.find((setting: any) => setting.type === "refer");
                if (cancellationSettings) {
                    state.settings.cancellation = {
                        status: cancellationSettings?.status,
                        cancellation_charge: cancellationSettings?.value.cancellation_charge,
                        cancellation_type: cancellationSettings?.value.cancellation_type,
                        cancellation_window: cancellationSettings?.value.cancellation_window,
                    };
                }
                if (noShowcurrentSettings) {
                    state.settings.noShow = {
                        status: noShowcurrentSettings?.status,
                        no_show_charge: noShowcurrentSettings.value.no_show_charge,
                        no_show_type: noShowcurrentSettings.value.no_show_type,
                    };
                }
                if (loyaltySettings) {
                    state.settings.loyalty = {
                        status: loyaltySettings?.status,
                        receive: loyaltySettings?.value.receive,
                        spend: loyaltySettings?.value.spend,
                        totalAmount: 0,
                    };
                }
                if (referSettings) {
                    state.settings.refer = {
                        referee: referSettings?.value.referee,
                        refferer: referSettings?.value.refferer,
                        referee_type: referSettings?.value.referee_type,
                        refferer_type: referSettings?.value.refferer_type,
                        status: referSettings.status,
                    };
                }
                if (cancellationSettings || noShowcurrentSettings) {
                    state.isNoShowPolicyVisible = cancellationSettings?.status || noShowcurrentSettings?.status;
                } else {
                    state.isNoShowPolicyVisible = false;
                }
            }
        });
    },
});

export const {
    setModifyingAppointmentId,
    setIsModifyingAppointment,
    setIsShowFinalizeBooking,
    setSelectedShopLocation,
    setSelectedSpecialist,
    setSelectedBookingDate,
    setSelectedBookingTime,
    setSelectedServices,
    setSelectedProducts,
    addProduct,
    removeProduct,
    setStep,
    setTempStore,
    setPaymentType,
    setPaymentOption,
    setOldPaymentOption,
    setIsValidPaymentType,
    setIsShowNoShowPolicy,
    setIsShowOrderSummary,
    setIsNoShowPolicyAccepted,
    setBookingInitialState,
    setSelectedPromocode,
    setProductCategory,
    setIsCardAdded,
    setSubmitTriggered,
    setIsCardLoading,
    setPreview,
    setBookingTotal,
    setNoShowPolicyVisible,
    setSettings,
    setBookingComplete,
} = bookingSlice.actions;

export const getBookingInfo = (state: RootState) => state.Booking;
export const shopSettings = (state: RootState) => state.Booking.settings || null;

import React, { useEffect, useRef, useState } from "react";
import Location from "./Location";
import About from "./About";
import Testimonial from "./Testimonial";
import Course from "./Course";
import Service from "./Service";
import Professional from "./Professional";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Gallery from "./Gallery";
import { selectTab, setIsScrollable } from "../../../redux/reducers/Landing.slice";
import Layout from "./Layout";
import Footer from "./Footer";
import { ISelectedTab } from "../../../Interface";
import { getSlug } from "src/utils/global-functions";
import NotConfigured from "src/app/NotConfigured";
import BarLoader from "src/components/BarLoader";
import Product from "./Product/Product";
import { useTranslation } from "react-i18next";
import useSectionObserver from "src/hooks/useSectionObserver";
import { useLocation, useNavigate } from "react-router-dom";
type SectionRefs = {
    [key in ISelectedTab]: React.RefObject<HTMLDivElement>;
};
interface IHasAnimated {
    hero: boolean;
    about: boolean;
    location: boolean;
    team: boolean;
    service: boolean;
    academy: boolean;
    product: boolean;
    testimonial: boolean;
    gallery: boolean;
    footer?: boolean;
    language?: boolean;
}

const Template2 = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const nameMapping: { [key: string]: string } = {
        about: t("About us"),
        location: t("Location"),
        team: t("Team"),
        service: t("Services"),
        academy: t("Academy"),
        product: t("Product"),
        testimonial: t("Testimonials"),
        gallery: t("Gallery"),
    };
    const settingLoading = useAppSelector((data) => data.Common.loading);
    const [isLoading, setIsLoading] = useState(true);
    const scrolling = useRef(false);
    const [hasAnimated, setHasAnimated] = useState<IHasAnimated>({
        hero: false,
        about: false,
        location: false,
        team: false,
        service: false,
        academy: false,
        product: false,
        testimonial: false,
        gallery: false,
    });

    useEffect(() => {
        dispatch(selectTab("about"));
    }, []);

    const settings: any = useAppSelector((data) => data.Common.settings)?.find((setting: any) => setting.type === (getSlug().isPreview ? "template_preview" : "template"))?.value;

    const locationSetting = settings?.location;
    const aboutSetting = settings?.about;
    const academySetting = settings?.academy;
    const gallerySetting = settings?.gallery;
    const testimonialsSetting = settings?.testimonial;
    const productsSetting = settings?.product;
    const servicesSetting = settings?.service;
    const teamSetting = settings?.team;
    const footerSetting = settings?.footer;
    const homeSettings = settings?.hero;

    const activeSection = useAppSelector((state) => {
        const initSelectedTab: any = state.LandingStates.initSelectedTab;
        return Object.keys(initSelectedTab).find((key: any) => initSelectedTab[key]) as ISelectedTab | undefined;
    });
    const isScrollable = useAppSelector((data) => data.LandingStates.isScrollable);
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();

    // Refs for all sections
    const sectionRefs: SectionRefs = {
        hero: useRef<HTMLDivElement>(null),
        about: useRef<HTMLDivElement>(null),
        location: useRef<HTMLDivElement>(null),
        team: useRef<HTMLDivElement>(null),
        service: useRef<HTMLDivElement>(null),
        academy: useRef<HTMLDivElement>(null),
        gallery: useRef<HTMLDivElement>(null),
        testimonial: useRef<HTMLDivElement>(null),
        product: useRef<HTMLDivElement>(null),
        language: useRef<HTMLDivElement>(null),
        footer: useRef<HTMLDivElement>(null),
    };

    let sectionIndex = 1;
    const [activeSettings, setActiveSettings] = useState<
        {
            name: string;
            tabName: ISelectedTab;
        }[]
    >([]);

    useEffect(() => {
        setIsLoading(settingLoading);
    }, [settingLoading]);

    useEffect(() => {
        const extractKeysWithStatusTrue = (obj: any) => {
            const result: ISelectedTab[] = [];
            for (const key in obj) {
                if (obj[key].status === true) {
                    result.push(key as ISelectedTab);
                }
            }
            return result;
        };

        const filterData = extractKeysWithStatusTrue(settings);

        const navLinks: { name: string; tabName: ISelectedTab }[] = Object.keys(nameMapping)
            .filter((key): key is ISelectedTab => filterData.includes(key as ISelectedTab))
            .map((tab) => ({
                name: nameMapping[tab],
                tabName: tab,
            }));

        setActiveSettings(navLinks);
    }, [settings]);

    useEffect(() => {
        if (state) {
            dispatch(selectTab(state.tabName));
        }
    }, [state]);

    useEffect(() => {
        if (state) {
            const sectionkey = state.tabName as ISelectedTab | undefined;
            if (sectionkey) {
                const sectionRef = sectionRefs[sectionkey]?.current;
                if (sectionRef) {
                    scrolling.current = true;
                    sectionRef.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "start",
                    });
                    navigate(".", { replace: true, state: undefined });

                    setTimeout(() => {
                        scrolling.current = false;
                        dispatch(setIsScrollable(false));
                    }, 1000);
                }
            }
        } else if (isScrollable && activeSection) {
            const sectionRef = sectionRefs[activeSection]?.current;
            if (sectionRef) {
                scrolling.current = true;
                sectionRef.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "start",
                });

                setTimeout(() => {
                    scrolling.current = false;
                    dispatch(setIsScrollable(false));
                }, 1000);
            }
        }
    }, [activeSection, isScrollable]);

    useSectionObserver({ sectionRefs, scrolling });

    useEffect(() => {
        if (activeSection && !hasAnimated[activeSection]) {
            setHasAnimated((prev) => ({
                ...prev,
                [activeSection]: true,
            }));
        }
    }, [activeSection, hasAnimated]);

    return (
        <div className="w-full flex flex-col flex-1 overflow-y-auto">
            {isLoading || settingLoading ? (
                <BarLoader />
            ) : settings?.length !== 0 && activeSettings.length !== 0 ? (
                <>
                    <Layout homeSettings={homeSettings}>
                        <div className="w-full mx-auto">
                            {aboutSetting?.status && (
                                <div ref={sectionRefs.about} className="main-section-spaching ">
                                    <About data={aboutSetting} sectionIndex={sectionIndex++} hasAboutAnimated={hasAnimated.about} />
                                </div>
                            )}
                            {locationSetting?.status && (
                                <div ref={sectionRefs.location} className="main-section-spaching ">
                                    <Location sectionIndex={sectionIndex++} data={locationSetting} hasLocationAnimated={hasAnimated.location} />
                                </div>
                            )}

                            {teamSetting?.status && (
                                <div ref={sectionRefs.team} className="main-section-spaching ">
                                    <Professional data={teamSetting} locationSetting={locationSetting} sectionIndex={sectionIndex++} hasTeamAnimated={hasAnimated.team} />
                                </div>
                            )}

                            {servicesSetting?.status && (
                                <div ref={sectionRefs.service} className="main-section-spaching ">
                                    <Service data={servicesSetting} sectionIndex={sectionIndex++} hasServiceAnimated={hasAnimated.service} />
                                </div>
                            )}

                            {academySetting?.status && (
                                <div ref={sectionRefs.academy} className="main-section-spaching ">
                                    <Course data={academySetting} sectionIndex={sectionIndex++} hasAcademyAnimated={hasAnimated.academy} />
                                </div>
                            )}

                            {productsSetting?.status && (
                                <div ref={sectionRefs.product} className="main-section-spaching">
                                    <Product data={productsSetting} sectionIndex={sectionIndex++} hasProductAnimated={hasAnimated.product} />
                                </div>
                            )}

                            {testimonialsSetting?.status && (
                                <div ref={sectionRefs.testimonial} className="main-section-spaching mb-0 flex flex-col flex-1">
                                    <Testimonial data={testimonialsSetting} sectionIndex={sectionIndex++} hasTestimonialAnimated={hasAnimated.testimonial} />
                                </div>
                            )}
                        </div>
                    </Layout>
                    {gallerySetting?.status && (
                        <div ref={sectionRefs.gallery} className="">
                            <Gallery data={gallerySetting} hasGalleryAnimated={hasAnimated.gallery} />
                        </div>
                    )}
                    <Footer data={footerSetting} activeSettings={activeSettings} />
                </>
            ) : (
                <NotConfigured />
            )}
        </div>
    );
};

export default Template2;

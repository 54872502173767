import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { axiosGet, axiosPatch, axiosPost } from "src/utils/requestClient";
import { API } from "src/constants/api";
import { toast } from "react-toastify";
import { checkFileTypeValidation, convertBase64ToFile, getCardTypeImage, getShortName, onError } from "src/utils/global-functions";
import BookingCard from "./Booking/BookingCard";
import { useTranslation } from "react-i18next";
import { currentShop, currentUser, me } from "src/redux/reducers/common/Common.slice";
import { usePhone } from "src/hooks/usePhone";
import { CountryCode } from "libphonenumber-js";
import { IAppointmentBooking } from "src/Interface/booked_appointment.interface";
import { initAction } from "./Profile.interface";
import Sidebar from "src/components/Sidebar";
import BookingView from "./Booking/BookingView";
import Tooltip from "src/components/Tooltip/Tooltip";
import { Copy06, InfoCircle, Pencil02, Plus } from "@untitled-ui/icons-react/build/cjs";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import { GoDotFill } from "react-icons/go";
import { errorCode, GLOBALVARIABLE } from "src/constants/common";
import CropperModal from "src/components/CropperModal/CropperModal";
import { s3Upload } from "src/utils/s3Operations";
import { s3Path } from "src/utils/s3Path";
import { ProgressSpinner } from "primereact/progressspinner";
import MCropperModal from "src/components/CropperModal/MCropperModal";

interface ILanding {
    handleAction: (type: string) => () => void;
    handleClose: () => void;
}

const Landing = ({ handleAction, handleClose }: ILanding) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const user = useAppSelector(currentUser);
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const shop = useAppSelector(currentShop);
    const uiState = useAppSelector((data) => data.UiStates);
    const { getFormatPhone } = usePhone();
    const [isStripeCardLoading, setIsStripeCardLoading] = useState(false);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [stripeCard, setStripeCard] = useState<any>(null);
    const [booking, setBooking] = useState<IAppointmentBooking | null>(null);
    const [referralConfig, setReferralConfig] = useState({ refferer: 0, refferer_type: "percentage" });
    const [action, setAction] = useState(initAction);
    const [isRefresh, setIsRefresh] = useState(true);
    const [upload, setUpload] = useState(false);
    const [cropData, setCropData] = useState<any>("");
    const [image, setImage] = useState<any>("");
    const [imageError, setImageError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isImageError, setIsImageError] = useState<boolean>(false);

    useEffect(() => {
        listStripeCards();
    }, []);

    useEffect(() => {
        if (isRefresh) {
            listBookings();
        }
    }, [isRefresh]);

    useEffect(() => {
        const filterShopSetting = shop.shop_settings.find((setting: { type: string }) => setting.type === "refer");
        if (filterShopSetting) {
            setReferralConfig({
                refferer: filterShopSetting.value?.refferer || 0,
                refferer_type: filterShopSetting.value?.refferer_type || "",
            });
        }
    }, [shop]);

    const listStripeCards = () => {
        setIsStripeCardLoading(true);
        axiosGet(API.STRIPE.CARD_LIST)
            .then((response) => {
                const cards = response.data.data.cards;
                const defaultCard = response.data.data.default_card_id;
                if (cards.length) {
                    setStripeCard(cards.find((card: any) => card.id === defaultCard));
                }
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsStripeCardLoading(false);
            });
    };

    const listBookings = () => {
        setIsBookingLoading(true);
        const payload = { skip: 0, type: "upcoming" };
        const params = { shop_id: shop.id };
        axiosPost(API.BOOKING.LIST, payload, params)
            .then((response) => {
                setBooking(response.data.data[0]);
            })
            .catch((error: any) => {
                toast.error(error?.message ?? "Something went wrong, Please try again");
            })
            .finally(() => {
                setIsBookingLoading(false);
                setIsRefresh(false);
            });
    };

    const navigateLocation = () => {
        handleClose();
        navigateBasedOnShopLocations();
    };

    const handleActions = (type: string, data: IAppointmentBooking) => (event: any) => {
        event.stopPropagation();
        setBooking(data);
        setAction((old) => ({ ...old, [type]: true }));
    };

    const handleActionClose = () => {
        setAction(initAction);
    };

    const handleProfileImage = (e: any) => {
        setUpload(false);
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const { result } = checkFileTypeValidation(files, GLOBALVARIABLE.accountImageSize);
        if (result) {
            setImageError(false);
            const reader = new FileReader();
            reader.onloadstart = () => {
                setImage("");
                setUpload(false);
            };
            reader.onloadend = () => {
                setImage(reader.result as any);
                setUpload(true);
            };
            reader.readAsDataURL(files[0]);
            e.target.value = null;
        } else {
            setImageError(true);
        }
    };

    const imageUploadFun = async (data: any) => {
        const fileInfo = convertBase64ToFile(data);
        setIsLoading(true);
        if (fileInfo && fileInfo.convertedFile && fileInfo?.filename) {
            const logoImageResponse: any = await s3Upload(fileInfo.convertedFile, `${s3Path.USER_PROFILE}${fileInfo?.filename}`);
            const imageStatus = logoImageResponse?.status;
            if (imageStatus === errorCode.updateSuccess) {
                axiosPatch(API.USER.USER_PROFILE_IMAGE_UPDATE, { profile_image_name: fileInfo?.filename })
                    .then(async () => {
                        await dispatch(me());
                        // handleClose();
                        return;
                    })
                    .catch((error) => {
                        const response = error.response.data;
                        if (response.status === errorCode.unprocessable) {
                            if (response.data) {
                                setImageError(true);
                                return;
                            }
                        }
                        toast.error(response.message);
                    })
                    .finally(() => setIsLoading(false));
            } else {
                setIsLoading(false);
            }
        }
    };

    const handleImageError = () => {
        setIsImageError(true);
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col w-full p-4 lg:p-5 bg-sidebarBgColor">
                <div className="flex justify-between mb-[30px] items-center">
                    <div className="flex-1 flex flex-row items-center gap-4">
                        <div className="flex relative">
                            {user?.profile_image_url && !isImageError ? (
                                <img
                                    src={user?.profile_image_url}
                                    alt=""
                                    className={`rounded-primary text-primaryAppointment flex justify-center items-center object-cover w-[64px] h-[64px] lg:h-[64px] min-w-[64px] lg:w-[64px] shadow-[0px_0px_8px_2px_#AAAAAA26] ${
                                        imageError ? "is-invalid" : ""
                                    }`}
                                    onError={handleImageError}
                                />
                            ) : (
                                <div
                                    className={`rounded-primary bg-mainBackgroundColor flex justify-center items-center shadow-[0px_0px_8px_2px_#AAAAAA26] text-primaryAppointment font-medium  w-[64px] h-[64px] ${
                                        imageError ? "is-invalid" : ""
                                    }`}
                                >
                                    <span className="text-[24px] leading-[36px] font-semibold">{getShortName(user?.full_name as string)}</span>
                                </div>
                            )}
                            <div className={`absolute h-[26px] w-[26px] right-[-6px] bottom-[-3px] flex justify-center items-center ${!isLoading && "bg-[#000000]"} rounded-full cursor-pointer`}>
                                {isLoading ? (
                                    <ProgressSpinner style={{ width: "26px", height: "26px" }} className="radio-loader" strokeWidth="8" animationDuration="1.5s" />
                                ) : (
                                    <>
                                        <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[26px] w-[26px]" onChange={handleProfileImage} />
                                        <Pencil02 className="w-[14px] h-[14px] text-white" />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <h1 className="text-[16px] font-bold text-txtAppointmentColor capitalize leading-[22.4px] mb-1 tracking-[-0.01rem] truncate-custom max-w-[160px] xs:max-w-[180px] sms:max-w-[254px]">
                                {user?.full_name}
                            </h1>
                            {user?.phone && (
                                <h3 className="text-sm text-txtAppointmentColor font-normal leading-[18.2px] tracking-[-0.01rem]">
                                    {getFormatPhone(user?.phone as string, user?.phone_country_code as CountryCode)}
                                </h3>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-center rounded-[6px] border border-borderCounter pr-1.5 pl-1 h-[24px] shadow-[0px_1px_2px_0px_#1018280D]">
                        <span className="flex items-center">
                            <GoDotFill size={12} className="text-primary w-3 h-3 mr-0.5" />{" "}
                            <span className="text-[12px] font-medium text-textCounterColor leading-[18px]">
                                {" "}
                                {t("Points")}: {user?.total_loyalty_points}
                            </span>
                        </span>

                        {/* <Tooltip description="Refferal points">
                            <InfoCircle id="alert_circle_svg" className="text-textGrey w-[18px] cursor-pointer" />
                        </Tooltip> */}
                    </div>
                </div>
                <div className="mb-[30px] flex flex-col">
                    <div className="flex items-center mb-[7px] gap-2">
                        <span className="text-sm font-bold leading-[18.2px] tracking-[-0.01rem] text-txtAppointmentColor">{t("Referral code")}</span>
                        <Tooltip
                            position="right"
                            description={t("Get 0% off any service when you refer a friend!", {
                                value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                            })}
                        >
                            <InfoCircle id="alert_circle_svg" className="text-textGrey w-[16px] h-[18.2px] cursor-pointer" />
                        </Tooltip>
                    </div>
                    <div className="flex relative h-[56px] w-full  border-dashed rounded-secondary border border-borderColorPrimary text-primary leading-[44px] text-sm lg:text-base font-semibold lg:font-medium items-center px-4 tracking-[-0.01rem] bg-containerBgColor">
                        {user?.referral_code}
                        <Copy06
                            onClick={() => {
                                navigator.clipboard.writeText(user ? user.referral_code : "");
                            }}
                            className="text-primary w-[18px] cursor-pointer absolute right-[16px]"
                        />
                    </div>
                </div>
                <div className="mb-[30px] flex flex-col">
                    <div className="flex justify-between items-center mb-1.5">
                        <h2 className="text-[14px] font-semibold text-txtAppointmentColor leading-[18px] tracking-[-0.01rem]">{t("Appointment")}</h2>
                        {booking && (
                            <button className="text-sm font-medium leading-[18.2px] tracking-[-0.01rem] flex text-primaryAppointment" onClick={navigateLocation}>
                                <Plus className="w-[16px] h-[16px] mr-1" /> {t("New Appointment")}
                            </button>
                        )}
                    </div>

                    {booking ? (
                        <div className="cursor-pointer gap-5 flex flex-col" onClick={handleActions("booking", booking)}>
                            <BookingCard booking={booking} handleAction={handleActions} />
                        </div>
                    ) : (
                        <div
                            className="w-full border border-dashed border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[92px] bg-contentBackground rounded-secondary justify-center bg-cardBackgroundColor cursor-pointer"
                            onClick={navigateLocation}
                        >
                            {isBookingLoading ? (
                                <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                            ) : (
                                <>
                                    <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                        <Plus className="w-[16px] h-[16px]" />
                                    </span>
                                    <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">{t("Create a New Appointment")}</p>
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className="mb-5">
                    <div className="flex justify-between items-center mb-1.5">
                        <h2 className="text-[14px] font-semibold text-txtAppointmentColor leading-[18px] tracking-[-0.01rem]">{t("Payment methods")}</h2>
                        {stripeCard && (
                            <button
                                className="text-sm font-medium leading-[18.2px] tracking-[-0.01rem] flex text-primaryAppointment"
                                onClick={handleAction(stripeCard ? "payment_method" : "create_payment_method")}
                            >
                                <Plus className="w-[16px] h-[16px] mr-1" /> {t("Add a payment method")}
                            </button>
                        )}
                    </div>

                    {stripeCard ? (
                        <div className="p-3 rounded-secondary border border-borderColorPrimary flex items-center">
                            <img src={getCardTypeImage(stripeCard.card.brand)} alt="" className="mr-3 rounded-lg w-[46px] h-[46px] border border-borderColorPrimary object-contain" />
                            <div className="flex flex-col">
                                <h4 className="text-[14px] font-semibold text-txtAppointmentColor tracking-[-0.01rem] leading-[19.6px] mb-0.5">
                                    {stripeCard.card.brand} {t("card ending in")} {stripeCard.card.last4}
                                </h4>
                                <span className="text-xs text-textGrey font-normal leading-[18px] tracking-[-0.01rem]">{t("Default")}</span>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="w-full border border-dashed border-borderColorPrimary px-[12px] py-[12px] flex flex-col items-center h-[92px] bg-contentBackground rounded-secondary justify-center bg-cardBackgroundColor cursor-pointer"
                            onClick={handleAction(stripeCard ? "payment_method" : "create_payment_method")}
                        >
                            {isStripeCardLoading ? (
                                <p className="text-textGrey text-sm font-normal items-center justify-center flex w-full pt-1">{t("Loading")}</p>
                            ) : (
                                <>
                                    <span className="flex items-center justify-center h-[26px] w-[26px] mb-1">
                                        <Plus className="w-[16px] h-[16px]" />
                                    </span>
                                    <p className="text-sm text-textGrey font-medium leading-[18px] tracking-[-0.01rem]">{t("Add a payment method")}</p>
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <Sidebar
                isBack
                isOpen={action.booking}
                isBottomView={uiState.isMobile}
                size="w-[460px]"
                customClass="sidebar-wrapper profile-sidebar !h-full"
                position={uiState.isMobile ? "bottom" : "right"}
                handleClose={handleActionClose}
                handleBack={handleActionClose}
                dismissable
            >
                {action.booking && booking && <BookingView booking={booking} handleClose={handleActionClose} handleOnModify={handleClose} setIsRefresh={setIsRefresh} />}
            </Sidebar>
            {upload &&
                (uiState.isTablet ? (
                    <CropperModal
                        imageUpload={imageUploadFun}
                        imageUrl={image}
                        setUpload={setUpload}
                        setCropData={setCropData}
                        defaultCropType="Square"
                        title={t("Crop Image")}
                        description={t("Upload a 400x400px image for the best results.")}
                        isCropType={false}
                    />
                ) : (
                    <MCropperModal
                        imageUpload={imageUploadFun}
                        imageUrl={image}
                        setUpload={setUpload}
                        setCropData={setCropData}
                        defaultCropType="Square"
                        title={t("Crop Image")}
                        description={t("Upload a 400x400px image for the best results.")}
                        isCropType={false}
                    />
                ))}
        </div>
    );
};

export default Landing;

import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { INSTAGRAM_URL } from "../../../../constants/common";
import { useShopNavigation } from "src/hooks/useShopNavigation";
import CustomButton from "src/components/CustomButton";
import { Star01 } from "@untitled-ui/icons-react/build/cjs";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";

const Layout = ({ children, homeSettings }: PropsWithChildren<{ homeSettings: any }>) => {
    const { navigateBasedOnShopLocations } = useShopNavigation();
    const { t } = useTranslation();
    const { getLocalizedSetting } = useLocalizedSetting();

    const title = getLocalizedSetting(homeSettings, "title");
    const subtitle = getLocalizedSetting(homeSettings, "subtitle");
    const buttonTitle = getLocalizedSetting(homeSettings, "button");
    const reviewsDes = getLocalizedSetting(homeSettings, "reviews_description");

    const convertNumber = (input: string) => {
        const number = parseFloat(input);

        if (Number.isInteger(number)) {
            return number.toFixed(1);
        } else {
            return `${Math.floor(number * 10) / 10.0}`;
        }
    };

    return (
        <section className="flex flex-col">
            {homeSettings?.status && (
                <div className="layout-columns">
                    <div
                        className="layout-home-column-media bg-cover bg-center"
                        style={{ backgroundImage: homeSettings?.is_background && homeSettings?.background_url ? `url(${homeSettings?.background_url})` : "" }}
                    >
                        {(homeSettings?.is_title ||
                            homeSettings?.is_subtitle ||
                            homeSettings?.is_reviews ||
                            homeSettings?.is_button ||
                            homeSettings?.is_instgram_button ||
                            homeSettings?.reviews_description) && (
                            <div className="h-full bg-[#00000061] bg-opacity-50 flex flex-col w-full justify-center mx-auto">
                                <div className="text-center max-w-[335px] lg:max-w-[616px] flex flex-col mx-auto justify-center items-center">
                                    {homeSettings?.is_title && (
                                        <h1 className="text-white text-[48px] leading-[52.8px] font-medium capitalize xl:text-[60px] xl:font-normal 2xl:leading-[81.6px] xl:leading-[72px] 2xl:text-[68px] mb-4">
                                            {title}
                                        </h1>
                                    )}

                                    {homeSettings?.is_subtitle && (
                                        <span className="text-white text-center lowercase first-letter:uppercase -tracking-[0.01rem] mb-8 text-[16px] leading-[24px] font-normal 2xl:text-[18px] 2xl:leading-[27px]">
                                            {subtitle}
                                        </span>
                                    )}
                                    <div className="flex flex-row gap-2 items-center mb-6">
                                        {homeSettings?.is_reviews && (
                                            <p className="text-white text-center flex text-[20px] leading-[24.2px] font-normal items-center">
                                                <Star01 className="text-white mr-2" />
                                                <span className="capitalize">
                                                    <span className="text-white text-center flex text-[20px] leading-[24.2px] font-semibold items-center">{convertNumber(homeSettings?.reviews)}</span>
                                                </span>
                                            </p>
                                        )}
                                        {reviewsDes && <span className="text-white text-center flex text-[20px] leading-[24.2px] font-normal items-center"> {reviewsDes}</span>}
                                    </div>
                                    {(homeSettings?.is_button || homeSettings?.is_instgram_button) && (
                                        <div className="flex flex-col lg:flex-row justify-center gap-2">
                                            {homeSettings?.is_button && (
                                                <CustomButton
                                                    primary
                                                    className="bg-primary flex items-center px-8 py-2 !h-[46px] !font-semibold rounded-secondary  uppercase text-center justify-center"
                                                    onClick={() => navigateBasedOnShopLocations()}
                                                >
                                                    {buttonTitle}
                                                </CustomButton>
                                            )}

                                            {homeSettings?.is_instgram_button && (
                                                <a
                                                    target="_blank"
                                                    href={`${INSTAGRAM_URL}/${homeSettings?.instgram_button}`}
                                                    className="border border-white txtcolor text-white rounded-secondary text-center justify-center font-normal flex items-center lg:px-6 px-5 py-2 h-[46px] uppercase"
                                                >
                                                    {t("Follow us on Instagram")}
                                                </a>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="layout-home-column-content">{children}</div>
                </div>
            )}
        </section>
    );
};

export default Layout;

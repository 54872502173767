export interface IMDProfessionalSwiper {
    data: any;
}

export interface IIndividualStaffDetail {
    staffList: any;
}

export const init = {
    640: {
        slidesPerView: 1,
        spaceBetween: 15,
    },
    768: {
        slidesPerView: 2,
        spaceBetween: 15,
    },
    1024: {
        slidesPerView: 3,
        spaceBetween: 20,
    },
    1280: {
        slidesPerView: 4,
        spaceBetween: 20,
    },
};

import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Eye, EyeOff, ChevronLeft, User01, InfoCircle } from "@untitled-ui/icons-react/build/cjs";
import InputWithLabel from "src/components/InputWithLabel";
import CustomButton from "src/components/CustomButton";
import Tooltip from "src/components/Tooltip/Tooltip";
import { getShortName } from "src/utils/global-functions";
import FloatingInputbox from "src/components/FloatingInputbox";

const MRegularProfile = ({ isLoading, handleLogout, onErrorImage, cropData, errorImage, onChangeCrop, referralConfig }: any) => {
    const { t } = useTranslation();
    const { control, watch } = useFormContext();
    const firstName = watch("first_name");
    const lastName = watch("last_name");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
    return (
        <div className={`flex flex-col px-4 mobile-model-inner`}>
            <div className="flex flex-col gap-4">
                <Controller
                    name="profile_image_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full flex flex-col">
                            <div className="flex flex-row gap-4 lg:gap-5 relative">
                                {!errorImage.profile && cropData ? (
                                    <img src={cropData} alt="" onError={onErrorImage("profile")} className="w-16 h-16 border-[0.75px] border-uploadProfileBg rounded-lg object-cover" />
                                ) : firstName && lastName ? (
                                    <div className="rounded-lg bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                        <span className="text-[24px] leading-[36px] font-medium max-w-[50px] overflow-hidden">{getShortName(`${firstName} ${lastName}`)}</span>
                                    </div>
                                ) : (
                                    <div className="rounded-lg bg-uploadProfileBg flex justify-center items-center  text-primaryAppointment font-medium h-[64px] w-[64px] ">
                                        <span className="text-[24px] leading-[36px] font-medium">
                                            <User01 className="text-[#8C9CBB] w-5 h-5" />
                                        </span>
                                    </div>
                                )}

                                <div
                                    className={`rounded-lg border border-uploadProfileBorder bg-uploadInputBg hover:shadow shadow-sm cursor-pointer flex-auto items-center justify-center py-[11px] px-0`}
                                >
                                    <div className="relative overflow-hidden cursor-pointer">
                                        <input type="file" id="image" className=" absolute opacity-0 rounded-full h-[64px] w-[64px]" onChange={onChangeCrop} />
                                        <label htmlFor="image" className="cursor-pointer text-center ">
                                            <div className="text-textGrey text-[14px] leading-[20px] font-normal">
                                                <p className="mb-1 flex items-center justify-center">
                                                    <span className="text-primary text-[14px] leading-[20px] font-semibold pr-1">{t("Click to upload")}</span>
                                                    <span className=" text-textGrey text-[14px] leading-[20px] font-normal">{t("or drag and drop")}</span>
                                                </p>
                                                <p className="text-[12px] text-textGrey font-normal leading-[18px]">{t("PNG or JPG file")}</p>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* {value && value.url && (
                        <ImageInfo className="w-[350px] h-[72px] mt-[6px] relative" name={value.name} url={value?.url} onRemove={handleRemoveImage} isApperance />
                    )} */}
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full">
                            <FloatingInputbox label={t("First Name")} type="text" required handleChange={onChange} value={value} name="first_name" error={error} />
                            {error && <p className="text-error !ml-0">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full ">
                            <FloatingInputbox label={t("Last Name")} type="text" required handleChange={onChange} value={value} name="last_name" error={error} />
                            {error && <p className="text-error !ml-0">{error.message}</p>}
                        </div>
                    )}
                />
                {/* <Controller
            name="gender"
            control={control}
            render={({ field: { onChange, value } }) => (
                <div className="w-full">
                    <SelectBox
                        placeholder={t("Select your gender")}
                        name="gender"
                        label={t("Gender")}
                        allowClear={false}
                        options={customGenderOption}
                        searchable={true}
                        onChange={(option: IGenderOptions | undefined) => onChange(option?.value)}
                        value={value && customGenderOption.find((option) => option.value === value)}

                    />
                    <p className="text-error">{errors.gender && errors.gender.message}</p>
                </div>
            )}
        /> */}
                <Controller
                    name="password"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full">
                            <FloatingInputbox
                                label={t("Password")}
                                buttonIconRight={isShowPassword ? <Eye width={16} className="cursor-pointer text-textGrey" /> : <EyeOff width={16} className="cursor-pointer text-textGrey" />}
                                handlerIconClick={() => setIsShowPassword(!isShowPassword)}
                                type={`${isShowPassword ? "text" : "password"}`}
                                handleChange={onChange}
                                // borderClass="!h-[44px] !mb-0"
                                required
                                value={value}
                                name="password"
                                autoComplete="new-password"
                                error={error}
                            />
                            {error && <p className="text-error !ml-0">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="password_confirmation"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-full">
                            <FloatingInputbox
                                label={t("Confirm password")}
                                buttonIconRight={isShowConfirmPassword ? <Eye width={16} className="cursor-pointer text-textGrey" /> : <EyeOff width={16} className="cursor-pointer text-textGrey" />}
                                handlerIconClick={() => setIsShowConfirmPassword(!isShowConfirmPassword)}
                                type={`${isShowConfirmPassword ? "text" : "password"}`}
                                handleChange={onChange}
                                required
                                value={value}
                                name="password_confirmation"
                                error={error}
                            />
                            {error && <p className="text-error !ml-0">{error.message}</p>}
                        </div>
                    )}
                />
                {/* <Controller
            name="birthdate"
            control={control}
            render={({ field: { onChange, value } }) => (
                <div className="w-full">
                    <InputWithLabel
                        label={t("Date of birth")}
                        name="birthdate"
                        inputClass="ba_form_control"
                        placeholder={t("DD-MM-YYYY")}
                        handleChange={(e) => {
                            if ((e.nativeEvent as any)?.inputType === "deleteContentBackward") {
                                onChange(e.target.value);
                                return;
                            }

                            let input = e.target.value.replace(/\D/g, "");
                            let formattedInput = "";

                            if (input.length > 1) {
                                formattedInput += `${input.substring(0, 2)}-`;
                                input = input.substring(2);
                            }

                            if (input.length > 1) {
                                formattedInput += `${input.substring(0, 2)}-`;
                                input = input.substring(2);
                            }

                            formattedInput += input.substring(0, 4);

                            e.target.value = formattedInput;
                            onChange(formattedInput);
                        }}
                        buttonIcon={value ? <IoCloseCircle className="text-secondaryTxt text-xl" /> : null}
                        handlerIconClick={() => onChange("")}
                        value={value}

                    />
                    <p className="text-error">{errors.birthdate && errors.birthdate.message}</p>
                </div>
            )}
        /> */}
                {referralConfig.status && (
                    <Controller
                        name="referral_code"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <div className="md:w-full flex flex-col">
                                <FloatingInputbox
                                    buttonIconRight={
                                        <div className="flex flex-row items-center">
                                            {/* <Tooltip
                                                position="top"
                                                description={t("Get 0% off any service when you refer a friend!", {
                                                    value: referralConfig.refferer_type === "percentage" ? `${referralConfig.refferer}%` : `$${referralConfig.refferer}`,
                                                })}
                                            >
                                                <InfoCircle className="text-textGrey cursor-pointer w-[16px]" />
                                            </Tooltip> */}
                                        </div>
                                    }
                                    type="text"
                                    label="Referral code"
                                    handleChange={(e) => onChange(e.target.value.toUpperCase())}
                                    value={value ?? ""}
                                    name="referral_code"
                                    error={error}
                                />
                                {error && <p className="text-error">{error.message}</p>}
                                <p className="text-[#475467] text-[12px] font-normal leading-[20px] mt-1.5">
                                    {t("Enter a referral code to receive")}
                                    &nbsp;
                                    <span className="font-semibold text-[#475467]">{referralConfig.referee_type === "percentage" ? `${referralConfig.referee}%` : `$${referralConfig.referee}`}</span>
                                    &nbsp;
                                    {t("your first appointment")}
                                </p>
                            </div>
                        )}
                    />
                )}
            </div>
            <div className="pt-5">
                <p className="text-txtcolor text-[12px] leading-[20px] font-normal text-left ">
                    {t("By signing up, you agree to the")}&nbsp;
                    <a href="https://www.getflair.ca/terms-of-service" target="_blank" className="text-primary font-semibold">
                        {t("Terms of Service")}
                    </a>
                    &nbsp;{t("and")}&nbsp;
                    <a href="https://www.getflair.ca/privacy-policy" target="_blank" className="text-primary font-semibold">
                        {t("Privacy Policy, ")}
                    </a>
                    &nbsp;
                    {t("and consent to receive personalized offers and update.")}
                </p>
                <div className="flex flex-col mt-[20px] max-md:mb-[20px]">
                    <CustomButton primary type="submit" size="w-full !h-[48px]" isLoading={isLoading} disabled={isLoading}>
                        {t("Continue")}
                    </CustomButton>
                    {/* <CustomButton
                    type="button"
                    icon={<ChevronLeft width={20} />}
                    className="!border-0 ring-0 !p-0 !w-[32px] !h-[32px] absolute -left-[11px] lg:left-[12px] -top-[18px] lg:top-[12px] hover:!bg-gray-100 !rounded-lg  !bg-transparent text-center text-textGrey hover:!text-txtcolor"
                    shadow={false}
                    onClick={handleLogout}
                ></CustomButton> */}
                </div>
            </div>
        </div>
    );
};

export default MRegularProfile;

import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import CustomButton from "src/components/CustomButton";
import InputWithLabel from "src/components/InputWithLabel";
import { useAppSelector } from "src/redux/hooks";
import { currentUser } from "src/redux/reducers/common/Common.slice";
import { IAccountSetting } from "../Profile.interface";
import { Edit02 } from "@untitled-ui/icons-react/build/cjs";

const WAccountSetting: FC<IAccountSetting> = ({ handleAction }) => {
    const { t } = useTranslation();
    const user = useAppSelector(currentUser);
    const { control } = useFormContext();
    return (
        <>
            <div className="w-full flex flex-row mt-[20px] gap-4">
                <Controller
                    name="first_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-1/2 ">
                            <InputWithLabel
                                label={t("First Name")}
                                borderClass="!h-[44px]"
                                type="text"
                                placeholder={t("Enter your first name")}
                                handleChange={onChange}
                                value={value}
                                name="first_name"
                            />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
                <Controller
                    name="last_name"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="w-1/2">
                            <InputWithLabel label={t("Last Name")} borderClass="!h-[44px]" type="text" placeholder={t("Enter your last name")} handleChange={onChange} value={value} name="last_name" />
                            {error && <p className="text-error">{error.message}</p>}
                        </div>
                    )}
                />
            </div>

            <div className="w-full mt-[18px] ">
                <InputWithLabel
                    label={t("Email")}
                    disabled
                    type="text"
                    borderClass="!h-[44px]"
                    buttonIconRight={<Edit02 className="w-[16px] h-[16px] text-primary absolute top-1/2 -translate-y-1/2 right-4" onClick={handleAction("email")} />}
                    handleChange={() => {}}
                    value={user?.email as string}
                    name="email"
                />
            </div>
        </>
    );
};

export default WAccountSetting;

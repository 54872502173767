import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { LOCAL_STORAGE_KEYS } from "./constants/common";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";

i18n.use(HttpApi)
    // connect with React
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        lng: localStorage.getItem(LOCAL_STORAGE_KEYS.currentLanguage) || "en",
        fallbackLng: "en",
        whitelist: ["en", "fr"],
        // keySeparator: false, // To allow dot(.) sign in the key
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    });

export default i18n;

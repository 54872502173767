import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ITabs } from "src/Interface";
import { selectTab } from "src/redux/reducers/Landing.slice";

interface ISectionRefs {
    [key: string]: React.RefObject<HTMLDivElement>;
}

interface IUseSectionObserverProps {
    sectionRefs: ISectionRefs;
    scrolling: React.RefObject<boolean>;
}

const useSectionObserver = ({ sectionRefs, scrolling }: IUseSectionObserverProps): void => {
    const dispatch = useDispatch();
    const handleSectionChange = (section: keyof ITabs["initSelectedTab"]) => {
        dispatch(selectTab(section));
    };
    useEffect(() => {
        const observerOptions: IntersectionObserverInit = {
            root: null,
            threshold: 0.3,
        };

        const observer = new IntersectionObserver((entries) => {
            if (scrolling.current) return;
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const sectionId = entry.target.getAttribute("data-section") as keyof ITabs["initSelectedTab"] | null;
                    if (sectionId) {
                        handleSectionChange(sectionId);
                    }
                }
            });
        }, observerOptions);

        const timer = setTimeout(() => {
            Object.entries(sectionRefs).forEach(([key, ref]) => {
                if (ref.current) {
                    ref.current.setAttribute("data-section", key);
                    observer.observe(ref.current);
                }
            });
        }, 100);

        return () => {
            clearTimeout(timer);
            observer.disconnect();
        };
    }, [sectionRefs, handleSectionChange, scrolling]);
};

export default useSectionObserver;

import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ManageSubmit from "./AppointmentPreview/ManageSubmit";
import { useAppSelector } from "src/redux/hooks";
import { InfoCircle } from "@untitled-ui/icons-react/build/cjs";
import moment from "moment";
import { getBookingInfo, shopSettings } from "./Booking.slice";

const NoShowPolicy: FC = () => {
    const { t } = useTranslation();
    const uiStates = useAppSelector((data) => data.UiStates);
    const bookingInfo = useAppSelector(getBookingInfo);
    const shopSettingsInfo = useAppSelector(shopSettings);
    const [dateTime, setDateTime] = useState({ date: "", time: "" });
    const [settingsData, setSettingsData] = useState({
        cancellation: { status: false, cancellation_charge: 0, cancellation_type: "amount", cancellation_window: 0 },
        noShow: { status: false, no_show_charge: 0, no_show_type: "amount" },
    });
    const [text, setText] = useState({
        header: t("Cancelation & No Show Policy"),
        subtitle: t("Review our no show and cancelation policies."),
    });

    useEffect(() => {
        const cancellationSettings = shopSettingsInfo.cancellation;
        const noShowSettings = shopSettingsInfo.noShow;

        if (cancellationSettings) {
            setSettingsData((prevState) => ({
                ...prevState,
                cancellation: {
                    status: cancellationSettings?.status,
                    cancellation_charge: cancellationSettings.cancellation_charge,
                    cancellation_type: cancellationSettings.cancellation_type,
                    cancellation_window: cancellationSettings.cancellation_window,
                },
            }));
        }
        if (noShowSettings) {
            setSettingsData((prevState) => ({
                ...prevState,
                noShow: {
                    status: noShowSettings?.status,
                    no_show_charge: noShowSettings.no_show_charge,
                    no_show_type: noShowSettings.no_show_type,
                },
            }));
        }

        if (cancellationSettings?.status && noShowSettings?.status) {
            setText({
                header: t("Cancelation & No Show Policy"),
                subtitle: t("Review our no show and cancelation policies."),
            });
        } else if (cancellationSettings?.status) {
            setText({
                header: t("Cancelation Policy"),
                subtitle: t("Review our cancelation policies."),
            });
        } else {
            setText({
                header: t("No Show Policy"),
                subtitle: t("Review our no show policies."),
            });
        }
    }, [shopSettingsInfo]);

    const calculateUpdatedDateTime = (selectedTime: string | undefined, selectedDate: string | undefined, hoursToSubtract: number) => {
        if (!selectedTime || !selectedDate) {
            return { time: "", date: "" };
        }

        const dateAndTime = moment(`${selectedDate} ${selectedTime}`, "YYYY-MM-DD HH:mm");

        const updatedDateTime = dateAndTime.subtract(hoursToSubtract, "hours");

        const updatedTime = updatedDateTime.format("hh:mm A");
        const updatedDate = updatedDateTime.format("DD-MM-YYYY");

        return { time: updatedTime, date: updatedDate };
    };

    useEffect(() => {
        if (bookingInfo.selectedBookingDate && bookingInfo.selectedBookingTime && settingsData.cancellation.cancellation_window) {
            const dateTimeObj = calculateUpdatedDateTime(bookingInfo.selectedBookingTime, bookingInfo.selectedBookingDate, settingsData.cancellation.cancellation_window);
            if (dateTimeObj) {
                setDateTime(dateTimeObj);
            }
        }
    }, [bookingInfo.selectedBookingTime, bookingInfo.selectedBookingDate, settingsData.cancellation.cancellation_window]);

    return (
        <div className="w-full flex flex-col flex-1">
            <div className="flex flex-col pt-[20px] lg:py-[50px] font-primary max-w-[440px] mx-auto relative flex-1">
                <div className="flex flex-col max-lg:h-full font-primary flex-1 max-lg:pb-[80px]">
                    <div className="flex flex-col">
                        <span className={uiStates.isMobile ? "login-model-title" : "login-model-title"}>{text.header}</span>
                        <span className={uiStates.isMobile ? "login-model-subtitle" : "login-model-subtitle"}>{text.subtitle}</span>
                    </div>
                    <div className="flex flex-col mt-[42px] gap-4 mb-5">
                        {settingsData.cancellation.status && (
                            <>
                                <div className={`leading-[24px] text-[16px] -tracking-[0.02rem] font-normal text-txtcolor`}>
                                    <span className="font-normal leading-[24px] text-[16px]">{t("Your have time")}</span>{" "}
                                    <span className="font-semibold leading-[24px] text-[16px]">{t(" until 12:00 AM", { value: dateTime.time })}</span>
                                    <span className="font-normal leading-[24px] text-[16px]">{t(" on")}</span>
                                    <span className="font-semibold leading-[24px] text-[16px]">{` ${dateTime.date}`}</span>
                                    <span className="font-normal leading-[24px] text-[16px]">{t(" to cancel this appointment without being charged.")}</span>
                                </div>
                                <div className="flex w-full border border-borderColorSecondary p-4 rounded-[12px] shadow-[0px_1px_2px_0px_#1018280D] gap-4">
                                    <span className="border border-borderColorPrimary flex rounded-[8px] shadow-[0px_1px_2px_0px_#1018280D] h-[40px] min-w-[40px] w-[40px] items-center justify-center">
                                        <InfoCircle className="w-[16px] text-txtcolor" />
                                    </span>
                                    <div className="flex flex-col flex-1">
                                        <span className="flex text-txtcolor font-semibold text-[14px] leading-[20px] mb-1">
                                            {t("Charge up to 50%", {
                                                value: `${settingsData.cancellation.cancellation_charge}${settingsData.cancellation.cancellation_type === "amount" ? "$" : "%"}`,
                                            })}
                                        </span>
                                        <span className="text-txtcolor font-normal text-[14px] leading-[20px]">
                                            {t("You will be charged a up to 50% fee if you cancel your appointment after this date.", {
                                                value: `${settingsData.cancellation.cancellation_charge}${settingsData.cancellation.cancellation_type === "amount" ? "$" : "%"}`,
                                            })}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                        {settingsData.noShow.status && (
                            <div className="flex w-full border border-borderColorSecondary p-4 rounded-[12px] shadow-[0px_1px_2px_0px_#1018280D] gap-4">
                                <span className="border border-borderColorPrimary flex rounded-[8px] shadow-[0px_1px_2px_0px_#1018280D] h-[40px] min-w-[40px] w-[40px] items-center justify-center">
                                    <InfoCircle className="w-[16px] text-txtcolor" />
                                </span>
                                <div className="flex flex-col flex-1">
                                    <span className="flex text-txtcolor font-semibold text-[14px] leading-[20px] mb-1">
                                        {t("Charge up to 100%", { value: `${settingsData.noShow.no_show_charge}${settingsData.noShow.no_show_type === "amount" ? "$" : "%"}` })}
                                    </span>
                                    <span className="text-txtcolor font-normal text-[14px] leading-[20px]">
                                        {t("You will be charged up to 100% if you do not attend your appointment on the date you specified.", {
                                            value: `${settingsData.noShow.no_show_charge}${settingsData.noShow.no_show_type === "amount" ? "$" : "%"}`,
                                        })}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <ManageSubmit isModal={true} />
            </div>
        </div>
    );
};

export default NoShowPolicy;

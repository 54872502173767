import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { errorCode } from "../../../../constants/common";
import { API } from "../../../../constants/api";
import { axiosPost } from "../../../../utils/requestClient";
import { Stripe, StripeElements, TokenResult } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import WSidebarPaymentElement from "./WSidebarPaymentElement";
import { useAppSelector } from "src/redux/hooks";
import MSidebarPaymentElement from "./MSidebarPaymentElement";

interface IProps {
    handleClose: (isSucceed?: boolean) => void;
    successBtnText: string;
}

interface IFormData {
    holdername: string;
    carddetails: string;
}

const SidebarPaymentElement = ({ handleClose, successBtnText }: IProps) => {
    const { t } = useTranslation();
    const uiState = useAppSelector((uiData) => uiData.UiStates);
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();
    const [isCreateCardLoading, setIsCreateCardLoading] = useState(false);

    const [cardError, setCardError] = useState("");

    const methods = useForm<IFormData>({
        defaultValues: {
            carddetails: "",
            holdername: "",
        },
    });

    const {
        handleSubmit,
        setError,
        formState: { isValid },
    } = methods;

    const onSubmit = async (data: IFormData) => {
        setIsCreateCardLoading(true);
        const cardElement = elements?.getElement(CardElement);

        if (stripe && cardElement) {
            const { error, token }: TokenResult = await stripe.createToken(cardElement);
            let formValid = true;
            if (error && error.message) {
                formValid = false;
                setCardError(error.message);
            }

            if (!data.holdername) {
                formValid = false;
                setError("holdername", {
                    type: "manual",
                    message: t("This field is required"),
                });
            }

            if (isValid && !error && formValid) {
                handleCreateCard(token?.id);
            } else {
                setIsCreateCardLoading(false);
            }
        } else {
            setIsCreateCardLoading(false);
        }
    };

    const handleCreateCard = async (tokenId: string) => {
        axiosPost(API.STRIPE.CARD_ADD, {
            token: tokenId,
        })
            .then(async (response: any) => {
                handleClose(true);
                return;
            })
            .catch((error) => {
                const response = error.response.data;
                if (response.status === errorCode.unprocessable || response.status === 402) {
                    setCardError(response.message);
                }
            })
            .finally(() => setIsCreateCardLoading(false));
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col h-full px-4 lg:px-5">
                {uiState.isTablet ? (
                    <WSidebarPaymentElement isCreateCardLoading={isCreateCardLoading} setCardError={setCardError} cardError={cardError} successBtnText={successBtnText} handleClose={handleClose} />
                ) : (
                    <MSidebarPaymentElement
                        isCreateCardLoading={isCreateCardLoading}
                        setCardError={setCardError}
                        cardError={cardError}
                        successBtnText={successBtnText}
                        handleClose={handleClose}
                        stripe={stripe}
                        elements={elements}
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default SidebarPaymentElement;

import React, { useEffect, useState } from "react";
import PopupModal from "src/components/PopupModal";
import InquireForm from "./InquireForm";
import { Check } from "@untitled-ui/icons-react/build/cjs";
import CustomButton from "src/components/CustomButton";
import { ICourse } from "./Course.interface";
import { onError } from "src/utils/global-functions";
import { useTranslation } from "react-i18next";
import { useLocalizedSetting } from "src/hooks/useLocalizedSetting";
import AnimatedTitle from "src/components/AnimatedTitle/AnimatedTitle";

const Course: React.FC<ICourse> = ({ data, sectionIndex, hasAcademyAnimated }) => {
    const { t } = useTranslation();
    const { getLocalizedSetting } = useLocalizedSetting();
    const [action, setAction] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleInquire = () => {
        setAction(true);
    };

    const handleClose = () => {
        setAction(false);
    };

    useEffect(() => {
        if (isSuccess) {
            const timer = setTimeout(() => {
                setIsSuccess(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isSuccess]);

    const title = getLocalizedSetting(data, "title");
    const description = getLocalizedSetting(data, "description");
    const buttonTitle = getLocalizedSetting(data, "button");

    return (
        <div className="">
            <div className="w-full flex items-center mb-[18px]">
                <div className="slice-label">
                    <span className="num">{sectionIndex}</span>
                    <span className="slice-text text-TextColor">
                        <AnimatedTitle title={t("ACADEMY")} isActive={hasAcademyAnimated} />
                    </span>
                </div>
            </div>
            <div className="h-[192.29px] xl:h-[375px] 2xl:h-[370px] 3xl:h-[436px] object-cover">
                <img className="w-full object-cover h-full" src={data.image_url} alt="" onError={onError("Location")} />
            </div>
            <div className="mt-7 lg:mt-[32px]">
                {/* <p className="text-[20px] leading-[24.2px] tracking-[-0.01rem] uppercase font-light mb-0.5">April 2024 (4 Days a Week)</p> */}
                <span className="text-[18px] xl:text-[20px] leading-[27px] xl:leading-[24.2px] tracking-[-0.01rem] uppercase font-light mb-0.5 text-TextColor">{data.date}</span>
                {data?.is_title && (
                    <h2 className="text-[32px] uppercase leading-[38.4px] xl:text-[42px] xl:leading-[50.4px] mb-0.5 font-normal tracking-[-0.01rem] text-TextColor">
                        <AnimatedTitle title={title} isActive={hasAcademyAnimated} />
                    </h2>
                )}

                <div className="flex flex-row mb-6">
                    <p className="text-[20px] leading-[30px] xl:leading-[24.2px] font-light tracking-[-0.01rem] text-TextColor uppercase">
                        {t("Price :")} ${Number(data.price).toFixed(2)}
                    </p>
                </div>

                {data.is_description && <p className="text-[16px] leading-[24px] -tracking-[0.01rem] font-light mb-6 text-TextColor">{description}</p>}

                {data?.is_button &&
                    (isSuccess ? (
                        <span className="flex items-center flex-row">
                            <Check className="text-primary w-5 h-5 mr-2" />
                            <span className="text-[16px] leading-[24px] font-medium tracking-[-0.01rem] uppercase text-primary">{t("Request submitted.")}</span>
                        </span>
                    ) : (
                        <CustomButton primary className="!h-[48px] uppercase !px-6 lg:!px-6 !font-medium" onClick={handleInquire}>
                            {buttonTitle}
                        </CustomButton>
                    ))}
            </div>
            {action && (
                <PopupModal onClose={handleClose} size={`max-w-[95%] min-w-[360px] mt-[76px] mx-auto md:w-[600px] font-primary outline-custom`} className="!pt-5 !pb-0 " dismissible position="center">
                    <InquireForm setIsSuccess={setIsSuccess} handleClose={handleClose} title={data?.is_title ? data.title : ""} />
                </PopupModal>
            )}
        </div>
    );
};

export default Course;
